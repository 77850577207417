import http from "../../http-common";

const asr = ({ data }) => {
  return http.post(`/api/v1/stats/asr`, data);
};

const crp = ({ data }) => {
  return http.post(`/api/v1/stats/crp`, data);
};

const crpGeo = ({ data }) => {
  return http.post(`/api/v1/stats/crpGeo`, data);
};

const top100cliAndCodes = ({ data, isChecked }) => {
  return http.post(
    `/api/v1/stats/top100cliAndCodes?top10checked=${isChecked}`,
    data
  );
};

const dataByZone = ({ data }) => {
  return http.post(`/api/v1/stats/dataByZone`, data);
};

const top10campaigns = ({ data }) => {
  return http.post(`/api/v1/stats/top10campaigns`, data);
};

const cliData = ({ data }) => {
  return http.post(`/api/v1/stats/cliData`, data);
};

const getCallCodesByCli = ({ data }) => {
  return http.post(`/api/v1/stats/getCallCodesByCli`, data);
};

const getCallAmountByCli = ({ data }) => {
  return http.post(`/api/v1/stats/getCallAmountByCli`, data);
};

const getAsrByCli = ({ data }) => {
  return http.post(`/api/v1/stats/getAsrByCli`, data);
};
const StatsService = {
  asr,
  crp,
  crpGeo,
  top100cliAndCodes,
  dataByZone,
  top10campaigns,
  cliData,
  getCallCodesByCli,
  getCallAmountByCli,
  getAsrByCli,
};

export default StatsService;
