import React from "react";
import Lottie from "react-lottie-player";
import comingSoon from "../../assets/images/comingSoon.json";

const ComingSoon = () => {
  return (
    <div className="flex items-center justify-center h-full bg-transparent">
      <div className="flex flex-col items-center text-center">
        <Lottie play loop animationData={comingSoon} className="h-1/2 w-3/5" />
        <h1 className="text-xl font-bold text-gray-800 mt-6 dark:text-white">
          New Feature Coming Soon! 🎉
        </h1>
        <p className="mt-2 text-sm font-semibold text-gray-600 dark:text-white">
          We're currently developing an exciting new feature that will soon be
          available. Stay tuned to enjoy all the benefits of our enhanced Call
          Detail Records (CDR), designed to provide more insights and improve
          your experience.
        </p>
      </div>
    </div>
  );
};

export default ComingSoon;
