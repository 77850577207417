import React, { useState, useEffect } from "react";
import PoolService from "../../services/pool/pool";

import IntlTelInput from "intl-tel-input/reactWithUtils";
import "intl-tel-input/styles";

const TesterForCustomers = ({ src, dst, setShowTester, isoCode = "MX" }) => {
  const [source, setSource] = useState(src ? `+${src}` : "");
  const [destination, setDestination] = useState(dst || "");
  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [isValid, setIsValid] = useState(false);

  const [response, setResponse] = useState({ status: "", message: "" });

  const handleTest = () => {
    setIsLoading(true);

    setResponse({ status: "", message: "" });
    const data = {
      server: "USA01",
      source,
      destination,
    };
    console.log(data);
    PoolService.spamTester({
      data,
    })
      .then(() => {
        setResponse({
          status: "success",
          message: `Call made succesfully`,
        });
        setIsLoading(false);
      })
      .catch(
        ({
          response: {
            data: { message },
          },
        }) => {
          setIsLoading(false);
          setResponse({
            status: "error",
            message:
              message ||
              "ERROR: please contact administrator support@astroline.com",
          });
        }
      );
  };
  useEffect(() => {
    return () => {
      setCountdown(0);
    };
  }, []);
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white dark:bg-gray-800 dark:text-white rounded-lg shadow-lg p-4 max-w-xl w-full">
        <div className="mb-4 w-full">
          <label className="text-2xl flex items-center mb-6 mr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 0 1 4.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 0 0-.38 1.21 12.035 12.035 0 0 0 7.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 0 1 1.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 0 1-2.25 2.25h-2.25Z"
              />
            </svg>
            Phone Number Tester
          </label>
          <p className="text-sm text-gray-700 dark:text-gray-300">
            This feature allows you to make a test call to any phone number
            within the same country as the source number to verify its status.
            You can check if it has any labels, if it's marked as spam, etc.
            There is no need to answer the call at the destination. However, if
            the call is answered, it will automatically hang up.
          </p>{" "}
        </div>
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
            Source
          </label>
          <div className="px-2 font-semibold dark:text-black">
            <IntlTelInput
              onChangeNumber={setSource}
              initialValue={source}
              onChangeValidity={setIsValid}
              disabled={true}
              initOptions={{
                initialCountry: isoCode,
                separateDialCode: true,
              }}
            />
          </div>
        </div>
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
            Destination
          </label>
          <div className="px-2 font-semibold dark:text-black">
            <IntlTelInput
              onChangeNumber={setDestination}
              onChangeValidity={setIsValid}
              initOptions={{
                initialCountry: isoCode,
                onlyCountries: [isoCode],
                separateDialCode: true,
              }}
            />
          </div>
          {!isValid && (
            <div className="text-red-500 text-sm mt-1">
              Please enter a valid phone number
            </div>
          )}
        </div>

        <div className="font-semibold text-sm mt-[1vh] mb-[1vh]">
          {response && response.status && response.message ? (
            <>
              {response.status === "error" ? (
                <div className="text-red-500">{response.message}</div>
              ) : (
                <div className="text-green-600">
                  {response.status === "success" && countdown > 0
                    ? `Call made succesfully`
                    : response.message}
                </div>
              )}
            </>
          ) : (
            ""
          )}
        </div>
        <div className="flex justify-end gap-4 text-sm">
          <button
            onClick={() => setShowTester(false)}
            className="px-4 py-2 bg-gray-500 text-white rounded-lg"
          >
            Cancel
          </button>
          <button
            onClick={handleTest}
            className={`px-4 py-2 text-white rounded-lg ${
              isValid ? "bg-green-700 hover:bg-green-600" : "bg-gray-400"
            }`}
            disabled={!isValid || isLoading}
          >
            {isLoading ? "Loading..." : "Make a call"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TesterForCustomers;
