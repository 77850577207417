import { useState } from "react";
import Breadcrumb from "../shared/Breadcrumb";
import CampaignsMain from "./campaigns/CampaignsMain";
import PoolMain from "./pool/PoolMain";
import useCustomerId from "../../hooks/useCustomerId";
import UsersMain from "./users/UsersMain";

const Panel = ({ title, icon, onClick, showBadge }) => (
  <div className="relative flex items-center justify-center" onClick={onClick}>
    {showBadge && (
      <div className="absolute top-0 right-0 bg-red-500 text-white text-xs px-2 py-1 rounded-full mr-2 mt-2">
        New Feature!
      </div>
    )}
    <div className="h-[20vh] w-[100vh] cursor-pointer flex flex-col items-center justify-center max-w-xs p-4 text-center bg-white dark:bg-[#1D1E24] dark:text-white rounded-xl shadow-xl hover:bg-gray-100">
      {icon}
      <h2 className="mb-3 text-3xl mt-6 font-semibold text-gray-900 dark:text-white">
        {title}
      </h2>
    </div>
  </div>
);
const ManagementMain = () => {
  const [currentPage, setCurrentPage] = useState("home");
  const customerId = useCustomerId();
  const navigate = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="relative h-[95vh] w-full">
      <Breadcrumb
        items={["Home"]}
        onClick={navigate}
        currentPage={currentPage}
      />
      <div className="flex items-center justify-center h-[90vh]">
        {currentPage === "home" && (
          <div className="flex flex-col md:flex-row items-center justify-center gap-4 h-full w-full">
            <Panel
              title="Campaigns"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-20 h-20"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46"
                  />
                </svg>
              }
              onClick={() => navigate("campaigns")}
            />
            <Panel
              title="Phone Number Pool"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-20 w-20"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                  />
                </svg>
              }
              onClick={() => navigate("pool")}
            />
            <Panel
              title="Users"
              showBadge={true}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-20 h-20"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                  />
                </svg>
              }
              onClick={() => navigate("users")}
            />
          </div>
        )}
        {currentPage === "campaigns" && (
          <CampaignsMain customerId={customerId} />
        )}
        {currentPage === "pool" && <PoolMain customerId={customerId} />}
        {currentPage === "users" && <UsersMain />}
      </div>
    </div>
  );
};

export default ManagementMain;
