import {
  Routes,
  Route,
  BrowserRouter,
  useLocation,
  Navigate,
} from "react-router-dom";
import { DarkModeProvider } from "./context/DarkModeContext";
import DashboardPage from "./pages/dashboard";
import ManagementPage from "./pages/management";
import ProfilePage from "./pages/profile";
import NotFoundPage from "./pages/notFound";
import Layout from "./layout/Layout";
import LoginPage from "./pages/login";
import { AuthProvider } from "./context/AuthContext";
import RequireAuth from "./services/login/requireAuth";
import AdminPage from "./pages/admin";
import { CustomerProvider } from "./context/CustomerContext";
import { ToastProvider } from "./context/ToastContext";
import CustomersMain from "./components/admin/customers/CustomersMain";

const MasterPrivateRoute = ({ element }) => {
  const masterToken = localStorage.getItem("mt");
  const location = useLocation();

  return masterToken ? (
    element
  ) : (
    <Navigate to="/dashboard" state={{ from: location }} />
  );
};

const AuthRedirect = ({ element }) => {
  const backendToken = localStorage.getItem("bt");
  const masterToken = localStorage.getItem("mt");

  if (backendToken) {
    return <Navigate to="/dashboard" />;
  }
  if (masterToken) {
    return <Navigate to="/master/customers" />;
  }

  return element;
};

function App() {
  return (
    <div className="App">
      <DarkModeProvider>
        <CustomerProvider>
          <ToastProvider>
            <AuthProvider>
              <BrowserRouter>
                <Routes>
                  <Route
                    path="/login"
                    element={<AuthRedirect element={<LoginPage />} />}
                  />
                  <Route
                    path="/"
                    element={
                      <RequireAuth>
                        <Layout>
                          <DashboardPage />
                        </Layout>
                      </RequireAuth>
                    }
                  ></Route>
                  <Route
                    path="/master/customers"
                    element={
                      <MasterPrivateRoute
                        element={
                          <Layout>
                            <CustomersMain />
                          </Layout>
                        }
                      />
                    }
                  />
                  <Route
                    path="/admin"
                    element={
                      <RequireAuth requiredScope="master">
                        <Layout>
                          <AdminPage />
                        </Layout>
                      </RequireAuth>
                    }
                  ></Route>
                  <Route
                    path="/dashboard"
                    element={
                      <RequireAuth>
                        <Layout>
                          <DashboardPage />
                        </Layout>
                      </RequireAuth>
                    }
                  ></Route>
                  <Route
                    path="/management"
                    element={
                      <RequireAuth>
                        <Layout>
                          <ManagementPage />
                        </Layout>
                      </RequireAuth>
                    }
                  ></Route>
                  <Route
                    path="/profile"
                    element={
                      <RequireAuth>
                        <Layout>
                          <ProfilePage />
                        </Layout>
                      </RequireAuth>
                    }
                  ></Route>
                  <Route
                    path="/not-found"
                    element={
                      <RequireAuth>
                        <Layout>
                          <NotFoundPage />
                        </Layout>
                      </RequireAuth>
                    }
                  ></Route>
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </BrowserRouter>
            </AuthProvider>
          </ToastProvider>
        </CustomerProvider>
      </DarkModeProvider>
    </div>
  );
}

export default App;
