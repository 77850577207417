import http from "../../http-common";

const login = (email, password) => {
  return http.post(`/api/v1/auth/signin`, { email, password });
};

const LoginService = {
  login,
  logout: () => {
    localStorage.removeItem("bt");
    localStorage.removeItem("mt");
  },
};

export default LoginService;
