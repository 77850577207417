import React, { useState, useEffect } from "react";

const Toast = ({ status, description, onClose }) => {
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prev) => Math.max(prev - 0.2, 0));
    }, 10);

    const autoClose = setTimeout(() => {
      onClose();
    }, 5000);

    return () => {
      clearInterval(timer);
      clearTimeout(autoClose);
    };
  }, [onClose]);

  const getStatusClasses = () => {
    switch (status) {
      case "success":
        return {
          bgColor: "bg-green-100",
          textColor: "text-green-800",
          progressColor: "bg-green-500",
        };
      case "error":
        return {
          bgColor: "bg-red-100",
          textColor: "text-red-800",
          progressColor: "bg-red-500",
        };
      case "warning":
        return {
          bgColor: "bg-yellow-100",
          textColor: "text-yellow-800",
          progressColor: "bg-yellow-500",
        };
      default:
        return {
          bgColor: "bg-blue-100",
          textColor: "text-blue-800",
          progressColor: "bg-blue-500",
        };
    }
  };

  const { bgColor, textColor, progressColor } = getStatusClasses();

  return (
    <div
      className={`fixed bottom-5 right-5 w-80 ${bgColor} shadow-lg rounded-lg overflow-hidden`}
    >
      <div
        className={`h-1 ${progressColor}`}
        style={{ width: `${progress}%` }}
      ></div>

      <div className="p-4 flex justify-between items-center">
        <div>
          <strong className={`block text-sm font-medium ${textColor}`}>
            {status.toUpperCase()}
          </strong>
          <p className={`text-sm ${textColor}`}>{description}</p>
        </div>
        <button
          onClick={onClose}
          className={`text-gray-500 hover:text-gray-900 font-bold text-lg`}
        >
          X
        </button>
      </div>
    </div>
  );
};

export default Toast;
