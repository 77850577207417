import { useState, useEffect, useCallback } from "react";
import { PlusCircleIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import UserService from "../../../services/users/users";
import AvatarComponent from "../../shared/Avatar";
import AvatarService from "../../../services/avatar/avatar";
import UserAdd from "./UserAdd";
import UserUpdate from "./UserUpdate";
import UserDelete from "./UserDelete";
import PermissionsKo from "../../shared/PermissionsKo"; // Asegúrate de la ruta correcta

const UsersMain = () => {
  const [showAddUser, setShowAddUser] = useState(false);
  const [showUpdateUser, setShowUpdateUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [avatarUrls, setAvatarUrls] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const roleStyle = (roles, roleName) => {
    const hasReadWrite = roles.some(
      (role) => role?.name === `${roleName}.ReadWrite`
    );
    const hasRead = roles.some((role) => role?.name === `${roleName}.Read`);

    if (hasReadWrite) {
      return "bg-green-500";
    } else if (hasRead) {
      return "bg-blue-500";
    }
    return "bg-white";
  };

  useEffect(() => {
    const loadAvatars = async () => {
      const emails = Array.from(
        new Set(
          users.flatMap((row) => [row.createdBy, row.updatedBy].filter(Boolean))
        )
      );

      const urls = await Promise.all(
        emails.map((email) => AvatarService.getAvatarByEmail(email))
      );
      const newAvatarUrls = emails.reduce((acc, email, index) => {
        acc[email] = urls[index];
        return acc;
      }, {});
      setAvatarUrls(newAvatarUrls);
    };

    loadAvatars();
  }, [users]);

  const fetchUsers = useCallback(() => {
    setUsers([]);
    setIsLoading(true);
    setError(null);

    UserService.listUsers()
      .then(({ data: { data: users } }) => {
        setUsers(users);
        setIsLoading(false);
      })
      .catch((error) => {
        if (
          error.response?.data?.message ===
          "Insufficient permissions to perform this action"
        ) {
          setError("permissions");
        } else {
          setError("Error fetching users. Please try again.");
        }
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const [selectedUser, setSelectedUer] = useState(null);
  const toggleAddUser = () => {
    setShowAddUser(!showAddUser);
  };
  const toggleUpdateUser = (user) => {
    setSelectedUer(user);
    setShowUpdateUser(!showUpdateUser);
  };

  // Renderizar estado de error
  if (error === "permissions") {
    return <PermissionsKo />;
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-full w-full">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full w-full text-xs">
      <div className="flex justify-between mb-1 pr-1 items-end">
        <div className="pl-2">
          <button
            onClick={toggleAddUser}
            className="flex items-center dark:text-white"
          >
            <PlusCircleIcon className="h-6 w-6 dark:text-white mr-1" /> Add User
          </button>
          {showAddUser && (
            <div
              className="fixed inset-0 bg-black bg-opacity-30 z-40"
              style={{ backdropFilter: "blur(4px)" }}
              onClick={() => setShowAddUser(false)}
            ></div>
          )}
          {showAddUser && (
            <UserAdd setShowAddUser={setShowAddUser} fetchUsers={fetchUsers} />
          )}

          {showUpdateUser && (
            <div
              className="fixed inset-0 bg-black bg-opacity-30 z-40"
              style={{ backdropFilter: "blur(4px)" }}
              onClick={() => setShowUpdateUser(false)}
            ></div>
          )}
          {showUpdateUser && (
            <UserUpdate
              setShowEditUser={setShowUpdateUser}
              fetchUsers={fetchUsers}
              user={selectedUser}
            />
          )}
        </div>
        <div className="dark:text-white">
          <div className="flex">
            <div className="h-3 w-3 rounded-full bg-green-500 mr-1"></div>
            Read and Write Permissions
          </div>
          <div className="flex">
            <div className="h-3 w-3 rounded-full bg-blue-500 mr-1"></div>
            Read Permissions
          </div>
          <div className="flex">
            <div className="h-3 w-3 rounded-full bg-white mr-1"></div>
            Without Permissions
          </div>
        </div>
      </div>

      <div className="overflow-x-auto hidden sm:block mt-4">
        <table className="min-w-full table-auto dark:text-white">
          <thead className="border-b sticky bg-slate-200 dark:bg-gray-700 text-xs">
            <tr>
              <th className="text-left px-4 py-2">Email</th>
              <th className="text-left px-4 py-2">Name</th>
              <th className="text-left px-4 py-2">User</th>
              <th className="text-left px-4 py-2">Campaign</th>
              <th className="text-left px-4 py-2">Numbering</th>
              <th className="text-left px-4 py-2">Stats</th>
              <th className="text-left px-4 py-2">Created By</th>
              <th className="text-left px-4 py-2">Created At</th>
              <th className="text-left px-4 py-2">Updated By</th>
              <th className="text-left px-4 py-2">Updated At</th>
              <th className="text-left px-4 py-2">Actions</th>
            </tr>
          </thead>
          {isLoading && (
            <tr>
              <td colSpan="11">
                <div className="relative">
                  <div className="w-full h-0.5">
                    <div className="h-full bg-blue-500 animate-progress-bar"></div>
                  </div>
                </div>
              </td>
            </tr>
          )}

          <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700 text-xs">
            {users.map((user) => (
              <tr key={user._id}>
                <td className="px-4 py-2 whitespace-nowrap text-gray-500 dark:text-gray-300">
                  {user.email}
                </td>
                <td className="px-4 py-2 whitespace-nowrap text-gray-500 dark:text-gray-300">
                  {`${user.name} ${user.surname1}`}
                </td>
                <td className="px-4 py-2">
                  <div
                    className={`h-3 w-3 rounded-full ${roleStyle(
                      user.roles,
                      "User"
                    )}`}
                  ></div>
                </td>
                <td className="px-4 py-2">
                  <div
                    className={`h-3 w-3 rounded-full ${roleStyle(
                      user.roles,
                      "Campaign"
                    )}`}
                  ></div>
                </td>
                <td className="px-4 py-2">
                  <div
                    className={`h-3 w-3 rounded-full ${roleStyle(
                      user.roles,
                      "Numbering"
                    )}`}
                  ></div>
                </td>
                <td className="px-4 py-2">
                  <div
                    className={`h-3 w-3 rounded-full ${roleStyle(
                      user.roles,
                      "Stats"
                    )}`}
                  ></div>
                </td>
                <td className="px-4 pt-2">
                  <span
                    className={`inline-flex items-center rounded-full pr-1 text-xs font-semibold`}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {user.createdBy && (
                        <div className="mr-2">
                          <AvatarComponent
                            email={user.createdBy}
                            height={"6"}
                            width={"6"}
                            avatarUrl={avatarUrls[user.createdBy]}
                          />
                        </div>
                      )}
                      {user.createdBy}
                    </div>
                  </span>
                </td>
                <td className="px-4 py-2">
                  {new Date(user.createdAt).toLocaleString(undefined, {
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  })}
                </td>
                <td className="px-4 pt-2">
                  <span
                    className={`inline-flex items-center rounded-full pr-1 text-xs font-semibold`}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {user.updatedBy && (
                        <div className="mr-2">
                          <AvatarComponent
                            email={user.updatedBy}
                            height={"6"}
                            width={"6"}
                            avatarUrl={avatarUrls[user.updatedBy]}
                          />
                        </div>
                      )}
                      {user.updatedBy}
                    </div>
                  </span>
                </td>
                <td className="px-4 py-2">
                  {new Date(user.updatedAt).toLocaleString(undefined, {
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  })}
                </td>
                <td className="px-4 py-2 items-center justify-center flex">
                  <PencilSquareIcon
                    className="h-5 w-5 mx-2 cursor-pointer"
                    onClick={() => {
                      toggleUpdateUser(user);
                    }}
                  />
                  <UserDelete user={user} fetchUsers={fetchUsers} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UsersMain;
