import React, { useState } from "react";
import PoolService from "../../services/pool/pool";
import IntlTelInput from "intl-tel-input/reactWithUtils";
import "intl-tel-input/styles";

const ReportPhoneNumber = ({
  phoneNumber,
  isoCode,
  setShowPhoneNumberReport,
}) => {
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const handleReport = () => {
    setIsLoading(true);
    setResponse(null);
    const data = {
      numbering: phoneNumber,
      reason: description,
    };
    PoolService.reportPhoneNumber({ data })
      .then(() => {
        setResponse({
          status: "success",
          message: "Success! The phone number has been reported",
        });
      })
      .catch(() => {
        setResponse({
          status: "error",
          message:
            "Error: Could not report the phone number. Please try again or contact support.",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white dark:bg-gray-800 dark:text-white rounded-lg shadow-lg p-6 max-w-md w-full">
        <h2 className="text-2xl mb-4 font-bold text-center">
          Report Phone Number
        </h2>
        <div className="flex flex-col items-center justify-center text-center">
          <p className="text-sm text-gray-700 dark:text-gray-300 mb-4">
            <IntlTelInput
              initialValue={`+${phoneNumber}`}
              disabled={true}
              initOptions={{
                initialCountry: isoCode,
                separateDialCode: true,
              }}
            />
          </p>

          <p className="text-sm text-gray-700 dark:text-gray-300 mb-4">
            Once the phone number has been reported, your Customer Success
            Manager (CSM) will be notified. They will get in touch with you as
            soon as possible after reviewing the number. The response time is no
            longer than 48 hours.
          </p>
        </div>

        <div className="my-6 mb-10">
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700 dark:text-gray-400"
          >
            Report Reason
          </label>
          <textarea
            id="description"
            rows="4"
            className="text-xs w-full mt-2 p-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            placeholder="Describe the reason for reporting this number..."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        {response && (
          <div
            className={`text-center text-sm font-semibold p-2 my-8 rounded-lg ${
              response.status === "success"
                ? "bg-green-100 text-green-700"
                : "bg-red-100 text-red-700"
            }`}
          >
            {response.message}
          </div>
        )}
        <div className="flex justify-end gap-4 text-sm">
          <button
            onClick={() => setShowPhoneNumberReport(false)}
            className="px-4 py-2 bg-gray-500 text-white rounded-lg"
          >
            Cancel
          </button>
          <button
            onClick={handleReport}
            className={`px-4 py-2 text-white rounded-lg ${
              description ? "bg-green-700 hover:bg-green-600" : "bg-gray-400"
            }`}
            disabled={isLoading || !description.trim()}
          >
            {isLoading ? "Loading..." : "Submit a report"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportPhoneNumber;
