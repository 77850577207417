import React, { useEffect, useState } from "react";
import { useDarkMode } from "../../context/DarkModeContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AsrVoosterLogoWhite from "../../assets/images/LogoWhite.svg";
import AsrVoosterLogoBlack from "../../assets/images/LogoBlack.svg";
import LoginService from "../../services/login/login";
import AvatarComponent from "./Avatar";
import AvatarService from "../../services/avatar/avatar";
import { useCustomer } from "../../context/CustomerContext";
import { FaDoorOpen } from "react-icons/fa";

const NavBar = () => {
  const { darkMode, setDarkMode } = useDarkMode();
  const [isNavBarMobileOpen, setIsNavBarMobileOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const location = useLocation();
  const [logo, setLogo] = useState(AsrVoosterLogoBlack);
  const storedCustomerName = localStorage.getItem("customerName");
  const storedCustomerUrl = localStorage.getItem("customerLogo");

  const mtToken = localStorage.getItem("mt");
  const btToken = localStorage.getItem("bt");

  let navigate = useNavigate();
  const [avatarUrl, setAvatarUrl] = useState("");
  const { customerName } = useCustomer();

  const toggleNavBarMobileOpen = () => {
    setIsNavBarMobileOpen(!isNavBarMobileOpen);
  };

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem("darkMode", newDarkMode ? "true" : "false");
  };

  const toggleUserMenuOpen = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };
  const handleLogout = () => {
    LoginService.logout();
    navigate("/login");
  };
  useEffect(() => {
    const isDarkMode = localStorage.getItem("darkMode") === "true";
    if (isDarkMode) {
      document.body.classList.add("dark");
      setLogo(AsrVoosterLogoWhite);
    } else {
      document.body.classList.remove("dark");
      setLogo(AsrVoosterLogoBlack);
    }
  }, [darkMode]);

  useEffect(() => {
    const loadAvatar = async () => {
      const email = localStorage.getItem("email");
      const url = await AvatarService.getAvatarByEmail(email);
      setAvatarUrl(url);
    };

    loadAvatar();
  }, []);

  const handleGoBack = () => {
    localStorage.removeItem("bt");
    navigate("/master/customers");
  };

  return (
    <nav className="bg-gray-200  dark:bg-[#1D1E24]">
      <div className="mx-auto px-2 sm:px-6 lg:px-2">
        <div className="relative flex h-[5vh] items-center justify-between">
          <div className="relative inset-y-0 left-0 flex items-center sm:hidden">
            <button
              type="button"
              className="relative inline-flex items-center justify-center rounded-md p-2 dark:text-gray-400 text-black dark:hover:bg-gray-700 dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-inset dark:focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={toggleNavBarMobileOpen}
            >
              <span className="absolute -inset-0.5"></span>
              <span className="sr-only">Open main menu</span>

              <svg
                className="block h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>

              <svg
                className="hidden h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex mr-[-50px] lg:mr-0 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center dark:text-white text-black">
              <img
                alt="asr-vooster-logo"
                src={logo}
                width={"150vh"}
                height={"5vh"}
              ></img>
            </div>
          </div>
          <div className="absolute left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2 flex space-x-4 h-full">
            {btToken && (
              <div className="flex space-x-4 h-full">
                <Link
                  to={`/dashboard`}
                  className={`${
                    location.pathname === "/dashboard" ||
                    location.pathname === "/"
                      ? "dark:text-white border-b-2 border-black dark:border-white"
                      : "dark:text-gray-300 text-gray-black"
                  } flex items-center justify-center px-3 text-sm font-medium text-center h-full py-3`}
                >
                  Dashboard
                </Link>
                <Link
                  to={`/management`}
                  className={`${
                    location.pathname === "/management"
                      ? "dark:text-white border-b-2 border-black dark:border-white"
                      : "dark:text-gray-300 text-gray-black"
                  } flex items-center justify-center px-3 text-sm font-medium text-center h-full py-3`}
                >
                  Management
                </Link>
              </div>
            )}
          </div>
          <div className="inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {btToken &&
              storedCustomerName &&
              storedCustomerName !== "UNKNOWN" && (
                <div className="flex items-center space-x-3 mr-4">
                  {mtToken ? (
                    <>
                      <div className="flex items-center">
                        <button
                          onClick={handleGoBack}
                          title="Go back to Admin Panel"
                          className="flex items-center text-black dark:text-white underline text-xs"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="h-7 w-7"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"
                            />
                          </svg>
                        </button>
                      </div>
                      <div className="h-8 border-l border-gray-300"></div>
                      <div className="flex flex-col justify-center items-start gap-1">
                        <div className="flex flex-col justify-center items-start">
                          <p className="text-[11px] dark:text-white text-gray-700">
                            Accessing the portal on behalf of
                          </p>
                          <div className="flex items-center">
                            <div className="mr-2">
                              <AvatarComponent
                                email={storedCustomerName}
                                height={"5"}
                                width={"5"}
                                avatarUrl={storedCustomerUrl}
                              />
                            </div>
                            <p className="font-semibold text-md dark:text-white text-gray-900">
                              {storedCustomerName}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="h-8 border-l border-gray-300"></div>
                    </>
                  ) : (
                    <>
                      <div className="flex flex-col justify-center items-start">
                        <p className="text-[11px] dark:text-white text-gray-700">
                          Accessing the portal on behalf of
                        </p>
                        <div className="flex items-center">
                          <div className="mr-2">
                            <AvatarComponent
                              email={storedCustomerName}
                              height={"5"}
                              width={"5"}
                              avatarUrl={storedCustomerUrl}
                            />
                          </div>
                          <p className="font-semibold text-md dark:text-white text-gray-900">
                            {storedCustomerName}
                          </p>
                        </div>
                      </div>

                      <div className="h-8 border-l border-gray-300"></div>
                    </>
                  )}
                </div>
              )}

            <div className="flex flex-col justify-center">
              <input
                type="checkbox"
                name="light-switch"
                className="light-switch sr-only"
              />
              <label
                className="relative cursor-pointer p-2"
                htmlFor="light-switch"
                onClick={toggleDarkMode}
              >
                <svg
                  className="dark:hidden"
                  width="16"
                  height="16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="fill-slate-300"
                    d="M7 0h2v2H7zM12.88 1.637l1.414 1.415-1.415 1.413-1.413-1.414zM14 7h2v2h-2zM12.95 14.433l-1.414-1.413 1.413-1.415 1.415 1.414zM7 14h2v2H7zM2.98 14.364l-1.413-1.415 1.414-1.414 1.414 1.415zM0 7h2v2H0zM3.05 1.706 4.463 3.12 3.05 4.535 1.636 3.12z"
                  />
                  <path
                    className="fill-slate-400"
                    d="M8 4C5.8 4 4 5.8 4 8s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4Z"
                  />
                </svg>
                <svg
                  className="hidden dark:block"
                  width="16"
                  height="16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="fill-slate-400"
                    d="M6.2 1C3.2 1.8 1 4.6 1 7.9 1 11.8 4.2 15 8.1 15c3.3 0 6-2.2 6.9-5.2C9.7 11.2 4.8 6.3 6.2 1Z"
                  />
                  <path
                    className="fill-slate-500"
                    d="M12.5 5a.625.625 0 0 1-.625-.625 1.252 1.252 0 0 0-1.25-1.25.625.625 0 1 1 0-1.25 1.252 1.252 0 0 0 1.25-1.25.625.625 0 1 1 1.25 0c.001.69.56 1.249 1.25 1.25a.625.625 0 1 1 0 1.25c-.69.001-1.249.56-1.25 1.25A.625.625 0 0 1 12.5 5Z"
                  />
                </svg>
                <span className="sr-only">Switch to light / dark version</span>
              </label>
            </div>
            <div className="relative ml-3">
              <div>
                <button
                  type="button"
                  className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  id="user-menu-button"
                  aria-expanded="false"
                  aria-haspopup="true"
                  onClick={toggleUserMenuOpen}
                >
                  <span className="absolute -inset-1.5"></span>
                  <span className="sr-only">Open user menu</span>
                  <AvatarComponent
                    email={localStorage.getItem("email")}
                    avatarUrl={avatarUrl}
                    width={8}
                    height={8}
                  />
                </button>
              </div>

              <div
                className={`${
                  isUserMenuOpen ? "" : "hidden"
                } absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu-button"
                tabindex="-1"
              >
                <a
                  href="/profile"
                  className="block px-4 py-2 text-sm text-gray-700"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-0"
                >
                  Your Profile
                </a>

                <a
                  onClick={handleLogout}
                  href="/"
                  className="block px-4 py-2 text-sm text-gray-700"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-2"
                >
                  Sign out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${isNavBarMobileOpen ? "" : "hidden"} sm:hidden`}
        id="mobile-menu"
      >
        <div className="space-y-1 px-2 pb-3 pt-2">
          <Link
            to="/dashboard"
            className={`${
              location.pathname === "/dashboard"
                ? "dark:bg-gray-900 bg-gray-500 text-white"
                : "dark:text-gray-300 text-gray-500"
            } block rounded-md px-3 py-2 text-base font-medium`}
            aria-current="page"
          >
            Dashboard
          </Link>
          <Link
            to="/management"
            className={`${
              location.pathname === "/management"
                ? "dark:bg-gray-900 bg-gray-500 text-white"
                : "dark:text-gray-300 text-gray-500"
            } block rounded-md px-3 py-2 text-base font-medium`}
          >
            Management
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
