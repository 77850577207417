import React, { createContext, useState, useContext } from "react";
import Toast from "../components/shared/Toast";

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState(null);

  const showToast = (status, description) => {
    setToast({ status, description });

    setTimeout(() => {
      setToast(null);
    }, 50000);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}

      {toast && (
        <Toast
          status={toast.status}
          description={toast.description}
          onClose={() => setToast(null)}
        />
      )}
    </ToastContext.Provider>
  );
};
