import React from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline"; // Si tienes Heroicons en tu proyecto

const PermissionsKo = () => {
  return (
    <div className="flex items-center justify-center min-h-full">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg max-w-md w-full text-center">
        <div className="flex justify-center mb-4">
          <ExclamationTriangleIcon className="h-12 w-12 text-yellow-500" />
        </div>
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
          Access Denied
        </h2>
        <p className="text-gray-700 dark:text-gray-300 mt-4">
          You don’t have the necessary permissions to view this content.
        </p>
        <p className="text-gray-700 dark:text-gray-300 mt-2">
          If you believe this is an error, please contact the administrator.
        </p>
      </div>
    </div>
  );
};

export default PermissionsKo;
