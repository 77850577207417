import React, { useState, useMemo, useEffect } from "react";
import { saveAs } from "file-saver";

import { PiFileCsvLight } from "react-icons/pi";
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";

import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { PiFilePdfLight } from "react-icons/pi";
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import StatsService from "../../services/stats/stats";
import Flag from "../shared/Flag";

const CliDataTable = ({
  startDate,
  endDate,
  filters,
  successValue,
  warningValue,
  setCliSelected,
  setIsUnauthorized,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isErrored, setIsErrored] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [selectedRowId, setSelectedRowId] = useState(null);

  useEffect(() => {
    setIsErrored(false);
    setIsLoading(true);
    const data = {
      startDate,
      endDate,
      filters,
    };
    StatsService.cliData({ data })
      .then(({ data: { message: cliData } }) => {
        setData(cliData);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setIsUnauthorized(true);
        }
        setIsErrored(true);
        setIsLoading(false);
      });
  }, [startDate, endDate, filters, successValue, warningValue]);

  const sortedAndFilteredData = useMemo(() => {
    let filteredData = data.filter((item) =>
      item.numbering.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (sortConfig.key !== null && sortConfig.direction !== null) {
      filteredData.sort((a, b) => {
        let isAscending = sortConfig.direction === "ascending";
        if (
          sortConfig.key === "numbering" ||
          sortConfig.key === "totalCalls" ||
          sortConfig.key === "answeredCalls" ||
          sortConfig.key === "volume" ||
          sortConfig.key === "asr"
        ) {
          let valueA = a[sortConfig.key];
          let valueB = b[sortConfig.key];
          return isAscending ? valueA - valueB : valueB - valueA;
        } else {
          if (
            a[sortConfig.key].toLowerCase() < b[sortConfig.key].toLowerCase()
          ) {
            return isAscending ? -1 : 1;
          }
          if (
            a[sortConfig.key].toLowerCase() > b[sortConfig.key].toLowerCase()
          ) {
            return isAscending ? 1 : -1;
          }
        }
        return 0;
      });
    }
    return filteredData;
  }, [data, searchTerm, sortConfig]);

  const onSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    } else if (
      sortConfig.key === key &&
      sortConfig.direction === "descending"
    ) {
      direction = null;
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    return sortConfig.key === key
      ? sortConfig.direction === "ascending"
        ? "▲"
        : "▼"
      : "";
  };

  const exportToExcel = () => {
    const dataForExcel = sortedAndFilteredData.map((item) => ({
      ...item,
      campaigns: extractNameFromCampaigns(item.campaigns).join(", "),
    }));
    const ws = XLSX.utils.json_to_sheet(dataForExcel);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "CLI Data");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, `CliRotationResults_${new Date().getTime()}.xlsx`);
  };

  const extractNameFromCampaigns = (campaigns) => {
    return campaigns.map((obj) => obj.name);
  };

  return (
    <div class="rounded-xl w-full dark:bg-[#1D1E24] bg-white dark:text-white text-gray-800 h-full">
      {isLoading || isErrored ? (
        <div className="status h-full w-full items-center justify-center flex">
          {isErrored ? (
            <>
              <div className="text-white font-medium text-sm bg-red-500 rounded-full px-2">
                Error loading chart
              </div>
            </>
          ) : (
            <div className="inline-block">
              <svg
                aria-hidden="true"
                class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 mr-2"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span>
                Please be patient, as this component is processing a large
                volume of data.
              </span>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="flex flex-col w-full">
            <div className="flex justify-between p-4">
              <h3 className="text-sm font-semibold h-[5%] w-full">
                CLI Analyzer
              </h3>
              <div className="flex mr-2">
                <div className="flex items-end justify-end mb-2 content-start">
                  <button
                    // onClick={exportToPDF}
                    className="ml-2 border border-1 dark:border-white rounded-full p-0.5"
                  >
                    <PDFDownloadLink
                      document={
                        <PDFTable
                          data={data}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      }
                      fileName={`CliRotationResults_${new Date().getTime()}.pdf`}
                    >
                      <PiFilePdfLight size={"20"} />
                    </PDFDownloadLink>
                  </button>
                </div>
                <div className="flex items-end justify-end mb-2 content-start">
                  <button
                    onClick={exportToExcel}
                    className="ml-2 border border-1 dark:border-white rounded-full p-0.5"
                  >
                    <PiMicrosoftExcelLogoFill size={"20"} />
                  </button>
                </div>
                <div>
                  <button className="flex items-end justify-end mb-2 content-start">
                    <CSVLink
                      data={sortedAndFilteredData.map((item) => ({
                        ...item,
                        campaigns: extractNameFromCampaigns(
                          item.campaigns
                        ).join(", "),
                      }))}
                      className="ml-2 border border-1 dark:border-white rounded-full p-0.5"
                      filename={`CliRotationResults_${new Date().getTime()}.csv`}
                    >
                      <PiFileCsvLight size={"20"} />
                    </CSVLink>
                  </button>
                </div>
              </div>
              <input
                type="text"
                placeholder="Search by numbering..."
                className="p-2 border rounded-lg h-6 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div
              id="cliDataTable"
              className="hidden sm:block align-middle w-full overflow-x-auto"
            >
              <div className="h-[77vh] overflow-y-scroll">
                <table className="min-w-full table-auto">
                  <thead className="sticky top-0 z-10 dark:bg-[#1D1E24] bg-white">
                    {" "}
                    {/* Mantiene el encabezado fijo */}
                    <tr className="border-b dark:border-white">
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer w-2/12"
                        onClick={() => onSort("phoneNumber")}
                      >
                        Phone Number
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer w-2/12"
                      >
                        Campaigns
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer w-1/12"
                      >
                        Prefix
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer w-2/12"
                      >
                        Zone
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer w-2/12"
                        onClick={() => onSort("totalCalls")}
                      >
                        Total Calls / Volume {getSortIcon("totalCalls")}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer w-2/12"
                        onClick={() => onSort("answeredCalls")}
                      >
                        Answered Calls / ASR {getSortIcon("answeredCalls")}
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer w-1/12"
                      >
                        Analyze
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedAndFilteredData.map((item, idx) => (
                      <tr
                        key={idx}
                        className={`border-b dark:border-white ${
                          selectedRowId === idx
                            ? "bg-blue-100 dark:bg-blue-900"
                            : ""
                        }`}
                      >
                        <td className="px-6 py-2 whitespace-nowrap text-xs flex items-end content-center text-center gap-2">
                          {item.isoCode ? (
                            <Flag code={item.isoCode} className="self-end" />
                          ) : (
                            <span className="w-[3em]"></span>
                          )}
                          <span className="self-end">{item.numbering}</span>
                        </td>

                        <td className="px-6 py-2">
                          <div className="flex flex-wrap items-center whitespace-nowrap text-xs">
                            {item.campaigns && item.campaigns.length > 0 ? (
                              item.campaigns.map((campaign, cIndex) => (
                                <span
                                  key={cIndex}
                                  style={{ backgroundColor: campaign.color }}
                                  className="inline-flex items-center px-2 text-xs rounded-full font-medium mr-1 mb-1 text-white"
                                >
                                  {campaign.name}
                                </span>
                              ))
                            ) : (
                              <span
                                style={{ backgroundColor: "brown" }}
                                className="inline-flex items-center px-2 text-xs rounded-full font-medium mr-1 mb-1 text-white"
                              >
                                Deactivated
                              </span>
                            )}
                          </div>
                        </td>
                        <td className="px-6 whitespace-nowrap text-xs">
                          {item.prefix}
                        </td>
                        <td className="px-6 whitespace-nowrap text-xs">
                          {item.zone}
                        </td>
                        <td className="px-6 whitespace-nowrap text-xs">
                          {item.totalCalls} / {item.volume}%
                        </td>
                        <td className="px-6 whitespace-nowrap text-xs">
                          {item.answeredCalls} / {item.asr}%
                        </td>
                        <td className="px-6 whitespace-nowrap text-xs items-center justify-center">
                          {item.campaigns && item.campaigns.length > 0 && (
                            <button
                              onClick={() => {
                                setCliSelected(item);
                                setSelectedRowId(idx);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"
                                />
                              </svg>
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              id="cliDataTableWithoutAnalyze"
              style={{ display: "none" }}
              className="hidden sm:block py-2 align-middle w-full overflow-x-auto"
            >
              <table>
                <thead>
                  <tr className="border-b dark:border-white">
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer w-2/12"
                      onClick={() => onSort("numbering")}
                    >
                      Numbering {getSortIcon("numbering")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer w-2/12"
                    >
                      Campaings
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer w-1/12"
                    >
                      Prefix
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer w-2/12"
                    >
                      Zone
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer w-1/12"
                      onClick={() => onSort("totalCalls")}
                    >
                      Total Calls {getSortIcon("totalCalls")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer w-1/12"
                      onClick={() => onSort("volume")}
                    >
                      Volume {getSortIcon("volume")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer w-1/12"
                      onClick={() => onSort("answeredCalls")}
                    >
                      Answered Calls {getSortIcon("answeredCalls")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer w-1/12"
                      onClick={() => onSort("asr")}
                    >
                      ASR {getSortIcon("asr")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer w-1/12"
                    >
                      Analyze
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedAndFilteredData.map((item, idx) => (
                    <tr
                      key={idx}
                      className={`border-b dark:border-white ${
                        selectedRowId === idx
                          ? "bg-blue-100 dark:bg-blue-900"
                          : ""
                      }`}
                    >
                      <td className="px-6 whitespace-nowrap text-xs">
                        {item.numbering}
                      </td>
                      <td className="px-6 py-2">
                        <div className="flex flex-wrap items-center whitespace-nowrap text-xs">
                          {item.campaigns && item.campaigns.length > 0 ? (
                            item.campaigns.map((campaign, cIndex) => (
                              <span
                                key={cIndex}
                                style={{ backgroundColor: campaign.color }}
                                className="inline-flex items-center px-2 text-xs rounded-full font-medium mr-1 mb-1 text-white"
                              >
                                {campaign.name}
                              </span>
                            ))
                          ) : (
                            <span
                              style={{ backgroundColor: "brown" }}
                              className="inline-flex items-center px-2 text-xs rounded-full font-medium mr-1 mb-1 text-white"
                            >
                              Deactivated
                            </span>
                          )}
                        </div>
                      </td>
                      <td className="px-6 whitespace-nowrap text-xs">
                        {item.prefix}
                      </td>
                      <td className="px-6 whitespace-nowrap text-xs">
                        {item.zone}
                      </td>
                      <td className="px-6 whitespace-nowrap text-xs">
                        {item.totalCalls}
                      </td>
                      <td className="px-6 whitespace-nowrap text-xs">
                        {item.volume}%
                      </td>
                      <td className="px-6 whitespace-nowrap text-xs">
                        {item.answeredCalls}
                      </td>
                      <td className="px-6 whitespace-nowrap text-xs">
                        {item.asr}%
                      </td>
                      <td className="px-6 whitespace-nowrap text-xs items-center justify-center">
                        {item.campaigns && item.campaigns.length > 0 && (
                          <button
                            onClick={() => {
                              setCliSelected(item);
                              setSelectedRowId(idx);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"
                              />
                            </svg>
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>{" "}
        </>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 10,
    width: "100%",
    height: "100%",
  },
  title: {
    fontSize: 16,
    marginBottom: 10,
    textAlign: "center",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    flexGrow: 1,
  },
  tableRow: { flexDirection: "row" },
  tableCell: {
    flex: 1,
    // marginVertical: 5,
    fontSize: 8,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
});

const calculateColumnWidths = (data) => {
  const columnWidths = [];
  const columnCount = 8;

  const totalWidth = 210;

  const columnWidth = totalWidth / columnCount;

  for (let i = 0; i < columnCount; i++) {
    columnWidths.push(columnWidth);
  }

  return columnWidths;
};

const PDFTable = ({ data, startDate, endDate }) => {
  const columnWidths = calculateColumnWidths(data);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>CLI Analyzer</Text>
        <Text style={styles.title}>
          Period:{" "}
          {new Date(startDate).toLocaleString(undefined, {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          })}{" "}
          -{" "}
          {new Date(endDate).toLocaleString(undefined, {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          })}
        </Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCell, { width: columnWidths[0] }]}>
              <Text>Numbering</Text>
            </View>
            <View style={[styles.tableCell, { width: columnWidths[1] }]}>
              <Text>Campaigns</Text>
            </View>
            <View style={[styles.tableCell, { width: columnWidths[2] }]}>
              <Text>Prefix</Text>
            </View>
            <View style={[styles.tableCell, { width: columnWidths[3] }]}>
              <Text>Zone</Text>
            </View>
            <View style={[styles.tableCell, { width: columnWidths[4] }]}>
              <Text>Total Calls</Text>
            </View>
            <View style={[styles.tableCell, { width: columnWidths[5] }]}>
              <Text>Volume</Text>
            </View>
            <View style={[styles.tableCell, { width: columnWidths[6] }]}>
              <Text>Answered Calls</Text>
            </View>
            <View style={[styles.tableCell, { width: columnWidths[7] }]}>
              <Text>ASR</Text>
            </View>
          </View>
          {data.map((item, idx) => (
            <View style={styles.tableRow} key={idx}>
              <View style={[styles.tableCell, { width: columnWidths[0] }]}>
                <Text>{item.numbering}</Text>
              </View>
              <View style={[styles.tableCell, { width: columnWidths[1] }]}>
                {item.campaigns.map((campaign, cIndex) => (
                  <Text key={cIndex}>{campaign.name}</Text>
                ))}
              </View>
              <View style={[styles.tableCell, { width: columnWidths[2] }]}>
                <Text>{item.prefix}</Text>
              </View>
              <View style={[styles.tableCell, { width: columnWidths[3] }]}>
                <Text>{item.zone}</Text>
              </View>
              <View style={[styles.tableCell, { width: columnWidths[4] }]}>
                <Text>{item.totalCalls}</Text>
              </View>
              <View style={[styles.tableCell, { width: columnWidths[5] }]}>
                <Text>{item.volume}%</Text>
              </View>
              <View style={[styles.tableCell, { width: columnWidths[6] }]}>
                <Text>{item.answeredCalls}</Text>
              </View>
              <View style={[styles.tableCell, { width: columnWidths[7] }]}>
                <Text>{item.asr}%</Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default CliDataTable;
