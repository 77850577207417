import React, { useEffect, useState } from "react";
import Pagination from "../../shared/Pagination";
import { useNavigate } from "react-router-dom";

import { PlusCircleIcon, EyeIcon } from "@heroicons/react/24/outline";

import CustomerAdd from "./CustomersAdd";
import CustomersEdit from "./CustomersEdit";
import MasterService from "../../../services/master/master";
import AvatarService from "../../../services/avatar/avatar";
import AvatarComponent from "../../shared/Avatar";
import Tester from "../../shared/Tester";

const CustomersTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(27);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [showEditCustomer, setShowEditCustomer] = useState(false);
  const [customerSelected, setCustomerSelected] = useState();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingCustomer, setLoadingCustomer] = useState(null);
  const [data, setData] = useState([]);
  const [error, setError] = useState({});
  const [avatarUrls, setAvatarUrls] = useState({});

  const filteredData = data.filter((item) => {
    return (item?.companyName?.toLowerCase() || "").includes(
      searchTerm.toLowerCase()
    );
  });

  useEffect(() => {
    const loadAvatars = async () => {
      const emails = Array.from(
        new Set(
          data.flatMap((row) => [row.createdBy, row.updatedBy].filter(Boolean))
        )
      );

      const urls = await Promise.all(
        emails.map((email) => AvatarService.getAvatarByEmail(email))
      );
      const newAvatarUrls = emails.reduce((acc, email, index) => {
        acc[email] = urls[index];
        return acc;
      }, {});
      setAvatarUrls(newAvatarUrls);
    };

    loadAvatars();
  }, [data]);

  const totalItems = filteredData.length;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    MasterService.listCustomers()
      .then(({ data: { data: customers } }) => {
        setData(customers);
        setIsLoading(false);
      })
      .catch((error) => {});
  }, []);

  const toggleAddCustomer = () => {
    setShowAddCustomer(!showAddCustomer);
  };

  const handleGenerateToken = async (customerId) => {
    setLoadingCustomer(customerId);
    setError({});

    try {
      const response = await MasterService.generateCustomerToken(customerId);
      if (response.data.status === "Success") {
        localStorage.setItem("bt", response.data.data.accessToken);
        localStorage.setItem(
          "customerLogo",
          response.data?.data?.companyData?.logoURL || "UNKNOWN"
        );
        localStorage.setItem(
          "customerName",
          response.data.data?.companyData?.companyName || "UNKNOWN"
        );
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      } else {
        setError((prevError) => ({
          ...prevError,
          [customerId]:
            "An error occurred while trying to authenticate the customer.",
        }));
      }
    } catch (err) {
      console.error("Error generating token:", err);
      setError((prevError) => ({
        ...prevError,
        [customerId]:
          "An error occurred while trying to authenticate the customer.",
      }));
    } finally {
      setLoadingCustomer(null);
    }
  };

  const [showTester, setShowTester] = useState(false);

  return (
    <div className="flex flex-col h-full w-full">
      <div className="p-4">
        <div className="flex justify-between items-start">
          <div className="flex items-center">
            <div className="relative flex">
              <button onClick={toggleAddCustomer}>
                <PlusCircleIcon className="h-6 w-6 dark:text-white" />
              </button>
              {showTester && (
                <div
                  className="fixed inset-0 bg-black bg-opacity-30 z-40"
                  style={{ backdropFilter: "blur(4px)" }}
                  onClick={() => setShowTester(false)}
                ></div>
              )}

              {showTester && <Tester setShowTester={setShowTester} />}
              {showAddCustomer && (
                <div
                  className="fixed inset-0 bg-black bg-opacity-30 z-40"
                  style={{ backdropFilter: "blur(4px)" }}
                  onClick={() => setShowAddCustomer(false)}
                ></div>
              )}
              {showEditCustomer && (
                <CustomersEdit
                  isOpen={showEditCustomer}
                  setIsOpen={setShowEditCustomer}
                  customer={customerSelected}
                />
              )}

              {showAddCustomer && (
                <CustomerAdd setShowAddCustomer={setShowAddCustomer} />
              )}
            </div>
            <div className="w-px h-6 bg-gray-300 mx-2"></div>
          </div>
          <span
            className="text-sm font-semibold dark:text-white cursor-pointer flex items-center gap-1"
            onClick={() => setShowTester(true)}
          >
            <span>Click here to open Voostester</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-4 w-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 0 1 4.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 0 0-.38 1.21 12.035 12.035 0 0 0 7.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 0 1 1.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 0 1-2.25 2.25h-2.25Z"
              />
            </svg>
          </span>
        </div>
      </div>

      <div className="overflow-x-auto hidden sm:block">
        <table className="min-w-full table-auto dark:text-white">
          <thead className="border-b sticky bg-slate-200 dark:bg-gray-700">
            <tr>
              <th className="text-left px-4 py-2">Name</th>
              <th className="text-left px-4 py-2">User Licenses</th>
              <th className="text-left px-4 py-2">Campaign Licenses</th>
              <th className="text-left px-4 py-2">Created By</th>
              <th className="text-left px-4 py-2">Created At</th>
              <th className="text-left px-4 py-2">Updated By</th>
              <th className="text-left px-4 py-2">Updated At</th>
              <th className="text-left px-4 py-2">Actions</th>
            </tr>
          </thead>
          {isLoading ? (
            <tr>
              <td colSpan="11" className="">
                <div className="relative">
                  <div className="w-full h-0.5">
                    <div className="h-full bg-blue-500 animate-progress-bar"></div>
                  </div>
                </div>
              </td>
            </tr>
          ) : (
            <tbody className="bg-white dark:bg-gray-800">
              {currentItems.length === 0 ? (
                <tr>
                  <td colSpan="14" className="text-center py-2">
                    <div className="flex justify-center items-center w-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-mood-empty-filled mr-1"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path
                          d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-2 10.66h-6l-.117 .007a1 1 0 0 0 0 1.986l.117 .007h6l.117 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-5.99 -5l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm6 0l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z"
                          stroke-width="0"
                          fill="currentColor"
                        ></path>
                      </svg>
                      - No customers found
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {currentItems.map((item, index) => (
                    <tr
                      key={index}
                      className="border-b dark:border-gray-700 text-sm font-medium"
                    >
                      <td className="px-4 pt-2 ">
                        <span
                          className={`inline-flex items-center rounded-full pr-1 text-sm font-semibold `}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {item.companyName && (
                              <div className="mr-2">
                                <AvatarComponent
                                  email={item.companyName}
                                  height={"6"}
                                  width={"6"}
                                  avatarUrl={item.logoURL}
                                />
                              </div>
                            )}
                            {item.companyName}
                          </div>
                        </span>
                      </td>
                      <td className="px-4 py-2">{item.userLicenses}</td>
                      <td className="px-4 py-2">{item.campaignLicences}</td>
                      <td className="px-4 pt-2 ">
                        <span
                          className={`inline-flex items-center rounded-full pr-1 text-sm font-semibold `}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {item.createdBy && (
                              <div className="mr-2">
                                <AvatarComponent
                                  email={item.createdBy}
                                  height={"6"}
                                  width={"6"}
                                  avatarUrl={avatarUrls[item.createdBy]}
                                />
                              </div>
                            )}
                            {item.createdBy}
                          </div>
                        </span>
                      </td>
                      <td className="px-4 py-2">
                        {new Date(item.createdAt).toLocaleString(undefined, {
                          timeZone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })}
                      </td>
                      <td className="px-4 pt-2 ">
                        <span
                          className={`inline-flex items-center rounded-full pr-1 text-sm font-semibold `}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {item.updatedBy && (
                              <div className="mr-2">
                                <AvatarComponent
                                  email={item.updatedBy}
                                  height={"6"}
                                  width={"6"}
                                  avatarUrl={avatarUrls[item.updatedBy]}
                                />
                              </div>
                            )}
                            {item.updatedBy}
                          </div>
                        </span>
                      </td>
                      <td className="px-4 py-2">
                        {new Date(item.updatedAt).toLocaleString(undefined, {
                          timeZone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })}
                      </td>
                      <td className="px-4 py-2 items-start justify-start flex">
                        <EyeIcon
                          className={`h-5 w-5 mx-2 cursor-pointer ${
                            loadingCustomer === item._id ? "animate-spin" : ""
                          } ${
                            loadingCustomer && loadingCustomer !== item._id
                              ? "opacity-50 cursor-not-allowed"
                              : ""
                          }`}
                          onClick={() =>
                            !loadingCustomer && handleGenerateToken(item._id)
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          )}
        </table>
      </div>

      <Pagination
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setItemsPerPage={setItemsPerPage}
      />
    </div>
  );
};

export default CustomersTable;
