const BadgeNewFeature = ({ text }) => {
  return (
    <div class="relative">
      <span class="absolute -top-[18px] -right-11 bg-yellow-200 text-yellow-800 text-[10px] px-2 rounded-xl">
        Coming Soon
      </span>
      {text}
    </div>
  );
};

export default BadgeNewFeature;
