import { useState } from "react";
import Breadcrumb from "../shared/Breadcrumb";

import CustomersMain from "./customers/CustomersMain";
import Tester from "../shared/Tester";

const AdminMain = () => {
  const [currentPage, setCurrentPage] = useState("home");

  const navigate = (page) => {
    setCurrentPage(page);
  };
  const [showTester, setShowTester] = useState(false);

  return (
    <div className="relative h-[95vh] w-full">
      <Breadcrumb
        items={["Home"]}
        onClick={navigate}
        currentPage={currentPage}
      />
      <div className="flex items-center justify-center h-[90vh]">
        {showTester && (
          <div
            className="fixed inset-0 bg-black bg-opacity-30 z-40"
            style={{ backdropFilter: "blur(4px)" }}
            onClick={() => setShowTester(false)}
          ></div>
        )}

        {showTester && <Tester setShowTester={setShowTester} />}
        {currentPage === "home" && (
          <div className="flex flex-col md:flex-row items-center justify-center gap-4 h-full w-full">
            <div
              className="flex items-center justify-center"
              onClick={() => navigate("customers")}
            >
              <div className="h-[20vh] w-[100vh] cursor-pointer flex flex-col items-center justify-center max-w-xs p-4 text-center bg-white dark:bg-[#1D1E24] dark:text-white rounded-xl shadow-xl hover:bg-gray-100 dark:hover:bg-gray-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-20 h-20"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"
                  />
                </svg>

                <h2 className="mb-3 text-4xl mt-6 font-semibold text-gray-900 dark:text-white">
                  Customers
                </h2>
              </div>
            </div>

            <div className="flex items-center justify-center">
              <div className="h-[20vh] w-[100vh] cursor-pointer flex flex-col items-center justify-center max-w-xs p-4 text-center  bg-white dark:bg-[#1D1E24] dark:text-white rounded-xl shadow-xl hover:bg-gray-100 dark:hover:bg-gray-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-20 h-20"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Zm3.75 11.625a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                  />
                </svg>

                <h2 className="mb-3 text-4xl mt-6 font-semibold text-gray-900 dark:text-white">
                  Logs
                </h2>
              </div>
            </div>
            <div
              className="flex items-center justify-center"
              onClick={() => setShowTester(true)}
            >
              <div className="h-[20vh] w-[100vh] cursor-pointer flex flex-col items-center justify-center max-w-xs p-4 text-center  bg-white dark:bg-[#1D1E24] dark:text-white rounded-xl shadow-xl hover:bg-gray-100 dark:hover:bg-gray-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-20 w-20"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 0 1 4.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 0 0-.38 1.21 12.035 12.035 0 0 0 7.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 0 1 1.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 0 1-2.25 2.25h-2.25Z"
                  />
                </svg>

                <h2 className="mb-3 text-4xl mt-6 font-semibold text-gray-900 dark:text-white">
                  Call Tester
                </h2>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <div className="h-[20vh] w-[100vh] cursor-pointer flex flex-col items-center justify-center max-w-xs p-4 text-center bg-white  dark:bg-gray-400 dark:text-white rounded-xl shadow-xl ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-20 h-20"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                  />
                </svg>
                <h2 className="mb-3 text-4xl mt-6 font-semibold text-gray-900 dark:text-white">
                  Carrier's Store
                </h2>
                Coming soon....
              </div>
            </div>
          </div>
        )}
        {currentPage === "customers" && <CustomersMain />}
        {/* {currentPage === "pool" && <PoolMain />} */}
      </div>
    </div>
  );
};

export default AdminMain;
