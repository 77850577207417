import axios from "axios";

const http = axios.create({
  baseURL: "https://asrvooster.com/",
  headers: {
    "Content-type": "application/json",
  },
});

http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("mt");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.message === "Forbidden"
    ) {
      console.log(error);
      localStorage.removeItem("mt");
      window.location = "/login";
    }
    return Promise.reject(error);
  }
);

export default http;
