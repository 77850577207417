import React, { useEffect, useState } from "react";
import Pagination from "../../shared/Pagination";
import {
  PencilSquareIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import CampaignService from "../../../services/campaigns/campaigns";
import CampaignsAdd from "./CampaignsAdd";
import CampaignsDelete from "./CampaignsDelete";
import CampaignsUpdate from "./CampaignsUpdate";
import AvatarService from "../../../services/avatar/avatar";
import AvatarComponent from "../../shared/Avatar";
import PermissionsKo from "../../shared/PermissionsKo";

const CampaignsTable = ({ customerId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(27);
  const [searchTerm, setSearchTerm] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [licensesLimit, setLicensesLimit] = useState();
  const [licensesUsed, setLicensesUsed] = useState();
  const [licensesAvailable, setLicensesAvailable] = useState();
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [showCampaignsUpdate, setShowCampaignsUpdate] = useState(false);
  const [showCampaignsDelete, setShowCampaignsDelete] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [avatarUrls, setAvatarUrls] = useState({});
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  useEffect(() => {
    const loadAvatars = async () => {
      const emails = Array.from(
        new Set(
          data.flatMap((row) => [row.createdBy, row.updatedBy].filter(Boolean))
        )
      );

      const urls = await Promise.all(
        emails.map((email) => AvatarService.getAvatarByEmail(email))
      );
      const newAvatarUrls = emails.reduce((acc, email, index) => {
        acc[email] = urls[index];
        return acc;
      }, {});
      setAvatarUrls(newAvatarUrls);
    };

    loadAvatars();
  }, [data]);

  const filteredData = data.filter((item) => {
    const searchTermMatch = (item.name?.toLowerCase() || "").includes(
      searchTerm.toLowerCase()
    );

    return searchTermMatch;
  });

  const totalItems = filteredData.length;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    CampaignService.listCampaigns({ customerId })
      .then(
        ({
          data: {
            data: {
              campaigns,
              campaignsLicensed,
              campaignsActive,
              campaignsAvailable,
            },
          },
        }) => {
          setData(campaigns);
          setIsLoading(false);
          setLicensesAvailable(campaignsAvailable);
          setLicensesLimit(campaignsLicensed);
          setLicensesUsed(campaignsActive);
        }
      )
      .catch((error) => {
        setIsLoading(false);
        if (error.response && error.response.status === 403) {
          setIsUnauthorized(true);
        }
      });
  }, [showCampaigns, showCampaignsDelete, showCampaignsUpdate, customerId]);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };
  const toggleShowCampaigns = () => {
    setShowCampaigns(!showCampaigns);
  };

  return (
    <div class="flex flex-col h-full">
      {isUnauthorized ? (
        <PermissionsKo />
      ) : (
        <>
          <div className="p-4">
            <div className="flex justify-between items-start">
              <div className="flex items-center">
                <div className="relative flex">
                  <button onClick={toggleShowCampaigns}>
                    <PlusCircleIcon className="h-6 w-6 dark:text-white" />
                  </button>
                  {showCampaigns && (
                    <div
                      className="fixed inset-0 bg-black bg-opacity-30 z-40"
                      style={{ backdropFilter: "blur(4px)" }}
                      onClick={() => setShowCampaigns(false)}
                    ></div>
                  )}

                  {showCampaigns && (
                    <CampaignsAdd
                      customerId={customerId}
                      setShowCampaigns={setShowCampaigns}
                      licensesAvailable={licensesAvailable}
                    />
                  )}

                  {showCampaignsUpdate && (
                    <div
                      className="fixed inset-0 bg-black bg-opacity-30 z-40"
                      style={{ backdropFilter: "blur(4px)" }}
                      onClick={() => setShowCampaigns(false)}
                    ></div>
                  )}
                  {showCampaignsUpdate && (
                    <CampaignsUpdate
                      customerId={customerId}
                      setShowCampaigns={setShowCampaignsUpdate}
                      campaignSelected={selectedCampaign}
                    />
                  )}
                  {showCampaignsDelete && (
                    <div
                      className="fixed inset-0 bg-black bg-opacity-30 z-40"
                      style={{ backdropFilter: "blur(4px)" }}
                      onClick={() => setShowCampaigns(false)}
                    ></div>
                  )}
                  {showCampaignsDelete && (
                    <CampaignsDelete
                      customerId={customerId}
                      setShowCampaigns={setShowCampaignsDelete}
                      campaign={selectedCampaign}
                    />
                  )}
                </div>
                <div className="w-px h-6 bg-gray-300 mx-2"></div>
              </div>
              <div className="flex items-center">
                {/* <div className="hidden lg:block lg:text-center lg:p-2 lg:w-full">
              <span className="text-xs dark:text-white pt-1 flex">
                Licenses:&nbsp; <b>{licensesLimit}</b>
                <div className="w-px h-5 bg-gray-300 mx-2 pb-1"></div>
                Used:&nbsp; <b>{licensesUsed}</b>
                <div className="w-px h-5 bg-gray-300 mx-2 pb-1"></div>
                Available:&nbsp; <b>{licensesAvailable}</b>
              </span>
            </div> */}

                <input
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search by campaign..."
                  className="input input-bordered border border-black bg-[#F7F8FC] dark:bg-gray-900 rounded-lg text-sm dark:text-white px-2 py-0.5"
                />
              </div>
            </div>
            {/* <div className="p-2 w-full lg:hidden justify-end flex text-right">
          <span className="text-xs dark:text-white pt-1 flex">
            Licenses:&nbsp; <b>{licensesLimit}</b>
            <div className="w-px h-5 bg-gray-300 mx-2 pb-1"></div>
            Used:&nbsp; <b>{licensesUsed}</b>
            <div className="w-px h-5 bg-gray-300 mx-2 pb-1"></div>
            Available:&nbsp; <b>{licensesAvailable}</b>
          </span>
        </div> */}
          </div>

          <div class="overflow-x-auto hidden sm:block">
            <table class="min-w-full table-auto dark:text-white ">
              <thead class="border-b sticky bg-slate-200 dark:bg-gray-700">
                <tr>
                  <th class="text-left px-4 py-2">Name</th>
                  <th class="text-left px-4 py-2">Description</th>
                  <th class="text-left px-4 py-2">Strategy</th>
                  <th class="text-left px-4 py-2">CLI Received</th>
                  <th class="text-left px-4 py-2">Pool Amount</th>
                  <th class="text-left px-4 py-2">Color</th>
                  <th class="text-left px-4 py-2">Created By</th>
                  <th class="text-left px-4 py-2">Created At</th>
                  <th class="text-left px-4 py-2">Updated By</th>
                  <th class="text-left px-4 py-2">Updated At</th>
                  <th class="text-left px-4 py-2">Actions</th>
                </tr>
              </thead>
              {isLoading ? (
                <tr>
                  <td colSpan="11" className="">
                    <div className="relative">
                      <div className="w-full h-0.5">
                        <div className="h-full bg-blue-500 animate-progress-bar"></div>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  <tbody class="bg-white dark:bg-gray-800">
                    {currentItems.length === 0 ? (
                      <tr>
                        <td colSpan="11" className="text-center py-2">
                          <div className="flex justify-center items-center w-full">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-mood-empty-filled mr-1"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="2"
                              stroke="currentColor"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <path
                                d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-2 10.66h-6l-.117 .007a1 1 0 0 0 0 1.986l.117 .007h6l.117 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-5.99 -5l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm6 0l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z"
                                stroke-width="0"
                                fill="currentColor"
                              ></path>
                            </svg>
                            - No campaigns found
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {currentItems.map((item, index) => (
                          <tr
                            key={index}
                            class="border-b dark:border-gray-700 text-xs font-medium"
                          >
                            <td class="px-4 py-2">{item.name}</td>
                            <td class="px-4 py-2">{item.description}</td>
                            <td class="px-4 py-2">{item.strategy}</td>
                            <td class="px-4 py-2">{item.cliReceived}</td>
                            <td className="px-4 py-2">{item.pool.length}</td>
                            <td className="px-4 py-2">
                              <span
                                key={item.color}
                                style={{ backgroundColor: item.color }}
                                className="inline-flex items-center px-2  text-xs rounded-full font-medium text-white"
                              >
                                {item.color}
                              </span>
                            </td>
                            <td className="px-4 pt-2 ">
                              <span
                                className={`inline-flex items-center rounded-full pr-1 text-xs font-semibold `}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {item.createdBy && (
                                    <div className="mr-2">
                                      <AvatarComponent
                                        email={item.createdBy}
                                        height={"6"}
                                        width={"6"}
                                        avatarUrl={avatarUrls[item.createdBy]}
                                      />
                                    </div>
                                  )}
                                  {item.createdBy}
                                </div>
                              </span>
                            </td>
                            <td class="px-4 py-2">
                              {new Date(item.createdAt).toLocaleString(
                                undefined,
                                {
                                  timeZone:
                                    Intl.DateTimeFormat().resolvedOptions()
                                      .timeZone,
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                }
                              )}
                            </td>
                            <td className="px-4 pt-2 ">
                              <span
                                className={`inline-flex items-center rounded-full pr-1 text-xs font-semibold `}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {item.updatedBy && (
                                    <div className="mr-2">
                                      <AvatarComponent
                                        email={item.updatedBy}
                                        height={"6"}
                                        width={"6"}
                                        avatarUrl={avatarUrls[item.updatedBy]}
                                      />
                                    </div>
                                  )}
                                  {item.updatedBy}
                                </div>
                              </span>
                            </td>
                            <td class="px-4 py-2">
                              {new Date(item.updatedAt).toLocaleString(
                                undefined,
                                {
                                  timeZone:
                                    Intl.DateTimeFormat().resolvedOptions()
                                      .timeZone,
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                }
                              )}
                            </td>
                            <td className="px-4 py-2 items-center justify-center flex">
                              <PencilSquareIcon
                                className="h-5 w-5 mx-2 cursor-pointer"
                                onClick={() => {
                                  setShowCampaignsUpdate(true);
                                  setSelectedCampaign(item);
                                }}
                              />
                              <TrashIcon
                                className="h-5 w-5 mx-2 cursor-pointer"
                                onClick={() => {
                                  setShowCampaignsDelete(true);
                                  setSelectedCampaign(item);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </>
              )}
            </table>
          </div>

          <div class="sm:hidden overflow-y-scroll">
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan="100%">
                    <div className="loading-bar"></div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <>
                {currentItems.length === 0 ? (
                  <tr className="">
                    <td colSpan="1" className="text-center py-2">
                      <div className="flex justify-center  items-center w-full dark:text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-mood-empty-filled mr-1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path
                            d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-2 10.66h-6l-.117 .007a1 1 0 0 0 0 1.986l.117 .007h6l.117 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-5.99 -5l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm6 0l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z"
                            stroke-width="0"
                            fill="currentColor"
                          ></path>
                        </svg>
                        - No campaigns found
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {currentItems.map((item, index) => (
                      <div
                        key={index}
                        class="bg-white dark:bg-gray-900 p-4 mb-2 rounded-lg shadow dark:text-white"
                      >
                        <div class="flex justify-between mb-2">
                          <span class="font-bold">Name:</span>
                          <span>{item.name}</span>
                        </div>
                        <div class="flex justify-between mb-2">
                          <span class="font-bold">Description:</span>
                          <span>{item.description}</span>
                        </div>
                        <div class="flex justify-between mb-2">
                          <span class="font-bold">CLI Received:</span>
                          <span>{item.cliReceived}</span>
                        </div>
                        <div class="flex justify-between mb-2">
                          <span class="font-bold">Pool Amount:</span>
                          <span>{item.pool.length}</span>
                        </div>
                        <div class="flex justify-between mb-2">
                          <span class="font-bold">Color:</span>
                          <span
                            key={item.color}
                            style={{ backgroundColor: item.color }}
                            className="inline-flex items-center px-2  text-xs rounded-full font-medium text-black dark:text-white"
                          >
                            {item.color}
                          </span>
                        </div>
                        <div class="flex justify-between mb-2">
                          <span class="font-bold">Created By:</span>
                          <span
                            className={`inline-flex items-center rounded-full pr-1 text-s`}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {item.createdBy && (
                                <div className="mr-2">
                                  <AvatarComponent
                                    email={item.createdBy}
                                    height={"6"}
                                    width={"6"}
                                    avatarUrl={avatarUrls[item.createdBy]}
                                  />
                                </div>
                              )}
                              {item.createdBy}
                            </div>
                          </span>
                        </div>
                        <div class="flex justify-between mb-2">
                          <span class="font-bold">Created At:</span>
                          <span>
                            {new Date(item.createdAt).toLocaleString(
                              undefined,
                              {
                                timeZone:
                                  Intl.DateTimeFormat().resolvedOptions()
                                    .timeZone,
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                second: "numeric",
                              }
                            )}
                          </span>
                        </div>
                        <div class="flex justify-between mb-2">
                          <span class="font-bold">Updated By:</span>
                          <span
                            className={`inline-flex items-center rounded-full pr-1 text-s`}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {item.updatedBy && (
                                <div className="mr-2">
                                  <AvatarComponent
                                    email={item.updatedBy}
                                    height={"6"}
                                    width={"6"}
                                    avatarUrl={avatarUrls[item.updatedBy]}
                                  />
                                </div>
                              )}
                              {item.updatedBy}
                            </div>
                          </span>
                        </div>
                        <div class="flex justify-between">
                          <span class="font-bold">Updated At:</span>
                          <span>
                            {new Date(item.updatedAt).toLocaleString(
                              undefined,
                              {
                                timeZone:
                                  Intl.DateTimeFormat().resolvedOptions()
                                    .timeZone,
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                second: "numeric",
                              }
                            )}
                          </span>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </div>

          <Pagination
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setItemsPerPage={setItemsPerPage}
          />
        </>
      )}
    </div>
  );
};

export default CampaignsTable;
