import React, { useState, useEffect } from "react";
import {
  Chart,
  Settings,
  BarSeries,
  Axis,
  ScaleType,
  LineAnnotation,
  AnnotationDomainType,
  LIGHT_THEME,
  DARK_THEME,
} from "@elastic/charts";
import "@elastic/charts/dist/theme_only_light.css";

import { useDarkMode } from "../../../context/DarkModeContext";
import StatsService from "../../../services/stats/stats";
import { EuiIcon } from "@elastic/eui";

const CalsPerCliAndAsr = ({
  startDate,
  endDate,
  filters,
  successValue,
  warningValue,
}) => {
  const [data, setData] = useState();
  const { darkMode } = useDarkMode();
  const [isLoading, setIsLoading] = useState(true);
  const [isErrored, setIsErrored] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showAsrValues, setShowAsrValues] = useState(false);

  const handldeIsChecked = () => {
    if (isChecked) {
      setIsChecked(false);
    } else {
      setIsChecked(true);
    }
  };

  useEffect(() => {
    setIsErrored(false);
    setIsLoading(true);
    const data = {
      startDate,
      endDate,
      filters,
    };
    StatsService.top100cliAndCodes({ data, isChecked })
      .then(({ data: { message: calls } }) => {
        setData(calls);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsErrored(true);
        setIsLoading(false);
      });
  }, [startDate, endDate, filters, isChecked, successValue, warningValue]);
  return (
    <div className="h-full p-2 w-full rounded-xl shadow-xl dark:bg-[#1D1E24] text-gray-800 bg-white dark:text-white">
      {isLoading || isErrored ? (
        <div className="status h-full w-full items-center justify-center flex">
          {isErrored ? (
            <>
              <div className="text-white font-medium text-sm bg-red-500 rounded-full px-2">
                Error loading chart
              </div>
            </>
          ) : (
            <>
              {" "}
              <svg
                aria-hidden="true"
                class="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </>
          )}
        </div>
      ) : (
        <>
          <div className="flex justify-between">
            <h3 className="text-sm font-semibold h-[10%] mb-2">
              TOP 100 CLI and CODES
            </h3>
            <div className="justify-end ">
              <div class="flex items-center dark:text-white font-semibold text-xs">
                TOP 10 with worst ASR
                <label className="relative flex items-center rounded-full cursor-pointer ml-1">
                  <input
                    type="checkbox"
                    name="strategy"
                    id="isChecked"
                    checked={isChecked}
                    onChange={() => handldeIsChecked()}
                    className="peer relative h-4 w-4 cursor-pointer appearance-none rounded-full border border-gray-900/20 bg-gray-900/10 dark:bg-white transition-all"
                  />
                  <span className="absolute text-white dark:text-black transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-3.5 w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                      stroke-width="1"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                </label>
              </div>
              <div class="flex items-center dark:text-white font-semibold text-xs mt-1 mb-1 text-right justify-end">
                ASR limits
                <label className="relative flex items-center rounded-full cursor-pointer ml-1">
                  <input
                    type="checkbox"
                    name="asrlimits"
                    id="showAsrValues"
                    checked={showAsrValues}
                    onChange={() => setShowAsrValues(!showAsrValues)}
                    className="peer relative h-4 w-4 cursor-pointer appearance-none rounded-full border border-gray-900/20 bg-gray-900/10 dark:bg-white transition-all"
                  />
                  <span className="absolute text-white dark:text-black transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-3.5 w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                      stroke-width="1"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="h-[90%] pb-2 w-full">
            {data && (
              <Chart>
                <Settings baseTheme={darkMode ? DARK_THEME : LIGHT_THEME} />

                <Axis id="bottom" position="bottom" />
                <Axis
                  id="left-axis"
                  position="left"
                  showGridLines
                  tickFormat={(d) => `${Number(d).toFixed(0)}%`}
                />
                {showAsrValues && (
                  <>
                    <LineAnnotation
                      id="warning"
                      domainType={AnnotationDomainType.YDomain}
                      dataValues={[{ dataValue: parseInt(successValue) }]}
                      marker={<EuiIcon type="alert" />}
                      markerPosition={"left"}
                      style={{
                        line: {
                          strokeWidth: 1,
                          stroke: "#f59e0b",
                          opacity: 1,
                        },
                      }}
                    />
                    <LineAnnotation
                      id="danger"
                      domainType={AnnotationDomainType.YDomain}
                      dataValues={[{ dataValue: parseInt(warningValue) }]}
                      marker={<EuiIcon type="error" />}
                      markerPosition={"left"}
                      style={{
                        line: {
                          strokeWidth: 1,
                          stroke: "#ef4444",
                          opacity: 1,
                        },
                      }}
                    />
                  </>
                )}

                <BarSeries
                  color={["#3FCEA6", "#288269", "#194F40", "#DDDDDD"]}
                  id="bars2"
                  displayValueSettings={true}
                  xScaleType={ScaleType.Linear}
                  yScaleType={ScaleType.Linear}
                  xAccessor="x"
                  yAccessors={["y"]}
                  stackAccessors={["x"]}
                  splitSeriesAccessors={["g"]}
                  data={data}
                />
              </Chart>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CalsPerCliAndAsr;
