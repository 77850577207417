import { useState } from "react";
import DashboardMain from "../components/dashboard/DashboardMain";
import CliDataMain from "../components/cliData/CliDataMain";
import {
  ArrowsRightLeftIcon,
  Bars2Icon,
  CurrencyDollarIcon,
  DocumentMagnifyingGlassIcon,
  GlobeAltIcon,
  LinkIcon,
  ListBulletIcon,
  PresentationChartBarIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/24/outline";
import BadgeNewFeature from "../components/shared/BadgeNewFeature";
import ComingSoon from "../components/shared/ComingSoon";

const MenuItem = ({ icon, label, children, isOpen, toggle }) => {
  return (
    <li>
      <div
        className="flex items-center px-2 py-6 hover:bg-gray-700 cursor-pointer h-12"
        onClick={toggle}
      >
        <span className="text-sm text-right justify-right items-end align-right flex gap-2">
          {icon}
          <span>{label}</span>
        </span>
      </div>
      {isOpen && <ul className="space-y-2 bg-gray-700">{children}</ul>}
    </li>
  );
};

const DashboardPage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("mainDashboard");
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <div className="flex h-[95vh] w-full">
      <aside
        className={`h-full bg-gray-200  dark:bg-[#1D1E24] dark:text-white transition-all duration-300 ${
          isSidebarOpen ? "w-60" : "w-10"
        }`}
      >
        <div className="flex justify-between items-center p-2 h-[94vh">
          <span className={`text-xl font-semibold block`}></span>
          <Bars2Icon
            className="h-6 w-6 cursor-pointer"
            onClick={toggleSidebar}
          />
        </div>
        <ul>
          <MenuItem
            icon={<PresentationChartBarIcon className="h-5 w-5" />}
            label={isSidebarOpen ? "Main Dashboard" : ""}
            toggle={() => setSelectedMenu("mainDashboard")}
          ></MenuItem>
          <MenuItem
            icon={<DocumentMagnifyingGlassIcon className="h-5 w-5" />}
            label={isSidebarOpen ? "CLI Analyzer" : ""}
            toggle={() => setSelectedMenu("cliData")}
          ></MenuItem>
          <MenuItem
            icon={<ListBulletIcon className="h-5 w-5" />}
            label={
              isSidebarOpen ? (
                <>
                  <BadgeNewFeature text={"CDR (Call Detail Records)"} />
                </>
              ) : (
                <></>
              )
            }
            toggle={() => setSelectedMenu("cdr")}
          ></MenuItem>
        </ul>
      </aside>
      <main className="flex-1 h-full">
        {selectedMenu === "mainDashboard" && <DashboardMain />}
        {selectedMenu === "cliData" && <CliDataMain />}
        {selectedMenu === "cdr" && <ComingSoon />}
      </main>
    </div>
  );
};

export default DashboardPage;
