import http from "../../http-common-master";

const listCustomers = () => {
  return http.get(`/api/v1/customers/list`);
};

const generateCustomerToken = (customerId) => {
  return http.get(
    `/api/v1/customers/generateCustomerToken?customerId=${customerId}`
  );
};

const MasterService = {
  listCustomers,
  generateCustomerToken,
};

export default MasterService;
