import React from "react";
import ReactCountryFlag from "react-country-flag";

const Flag = ({ code }) => {
  return (
    <div>
      <ReactCountryFlag
        countryCode={code || "WLD"}
        style={{
          width: "3em",
        }}
        svg
      />
    </div>
  );
};

export default Flag;
