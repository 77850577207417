import React from "react";

const AvatarComponent = ({ width, height, avatarUrl }) => {
  return (
    <img
      className={`w-${width ? width : "11"} h-${
        height ? height : "11"
      } rounded-full`}
      src={
        avatarUrl ||
        "https://tsensor.online/wp-content/uploads/2020/04/avatar-icon-png-105-images-in-collection-page-3-avatarpng-512_512.png"
      }
      alt="Avatar"
    />
  );
};

export default AvatarComponent;
