import http from "../../http-common";

const getAsrValues = () => {
  return http.get(`/api/v1/customers/getAsrValues`);
};

const updateAsrValues = ({ asrValues }) => {
  return http.put(`/api/v1/customers/updateAsrValues`, { asrValues });
};

const CustomerService = {
  getAsrValues,
  updateAsrValues,
};

export default CustomerService;
