import { useState } from "react";
import { TrashIcon } from "@heroicons/react/24/outline";
import UserService from "../../../services/users/users";
import { useToast } from "../../../context/ToastContext";

const UserDelete = ({ user, fetchUsers }) => {
  const { showToast } = useToast();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const currentUserEmail = localStorage.getItem("email");

  const handleDeleteUser = () => {
    setIsLoading(true);
    UserService.deleteUser({ userId: user._id })
      .then(() => {
        showToast("success", "User deleted successfully!");
        fetchUsers();
        setIsLoading(false);
        setShowDeleteModal(false);
      })
      .catch((error) => {
        console.error(error);
        showToast("error", "Error deleting user. Please try again.");
        setIsLoading(false);
      });
  };

  return (
    <>
      <TrashIcon
        className={`h-5 w-5 mx-2 cursor-pointer ${
          currentUserEmail === user.email
            ? "text-gray-400 cursor-not-allowed"
            : ""
        }`}
        onClick={() => {
          if (currentUserEmail !== user.email) {
            setShowDeleteModal(true);
          }
        }}
        title={
          currentUserEmail === user.email
            ? "You cannot delete your own account"
            : "Delete user"
        }
        style={currentUserEmail === user.email ? { pointerEvents: "none" } : {}}
      />

      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-lg font-semibold dark:text-white">
              Are you sure you want to delete this user?
            </h2>
            <p className="text-sm text-gray-500 dark:text-gray-300">
              This action cannot be undone.
            </p>

            <div className="mt-6 flex justify-end gap-4">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="px-4 py-2 bg-gray-500 text-white rounded-lg"
              >
                No, take me back
              </button>
              <button
                onClick={handleDeleteUser}
                className="px-4 py-2 bg-red-500 text-white rounded-lg"
                disabled={isLoading}
              >
                {isLoading ? "Deleting..." : "Yes, proceed"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserDelete;
