// import { PiFilePdfLight } from "react-icons/pi";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
import CallAmountPerCli from "./charts/TimelineCallAmountPerCli";
import TimelineAsrPerCli from "./charts/TimelineAsrPerCli";
import TimelineCallCodes from "./charts/TimelineCallCodes";
import { VscCallOutgoing, VscWarning } from "react-icons/vsc";

import { useEffect, useState } from "react";
import Tester from "../shared/Tester";
import TesterForCustomers from "../shared/TesterForCustomers";
import ReportPhoneNumber from "../shared/ReportPhoneNumber";
import Flag from "../shared/Flag";

const CliDataDetails = ({
  cliSelected: {
    numbering,
    campaigns,
    prefix,
    zone,
    totalCalls,
    volume,
    answeredCalls,
    asr,
    isoCode,
  },
  startDate,
  endDate,
}) => {
  const [selectedNumber, setSelectedNumber] = useState();
  const [selectedIsoCode, setSelectedIsoCode] = useState();

  const [showTester, setShowTester] = useState(false);
  const [showPhoneNumberReport, setShowPhoneNumberReport] = useState(false);
  const [selectedOption, setSelectedOption] = useState("24h");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // const exportToPDF = () => {
  //   const input = document.getElementById("component-to-export");

  //   html2canvas(input, { backgroundColor: null }).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("p", "mm", "a4");
  //     const width = pdf.internal.pageSize.getWidth();
  //     const height = pdf.internal.pageSize.getHeight();
  //     pdf.addImage(imgData, "PNG", 0, 0, width, height);
  //     pdf.save("componente.pdf");
  //   });
  // };
  const [triggerRerender, setTriggerRerender] = useState(false);

  const onClose = () => {
    if (showTester) {
      setShowTester(false);
      setTriggerRerender((prev) => !prev);
    }
    if (showPhoneNumberReport) {
      setShowPhoneNumberReport(false);
      setTriggerRerender((prev) => !prev);
    }
  };

  const testerClicked = ({ number, isoCode }) => {
    setSelectedNumber(number);
    setSelectedIsoCode(isoCode);
    setShowTester(true);
  };

  const phoneNumberReportClicked = ({ number, isoCode }) => {
    setSelectedNumber(number);
    setSelectedIsoCode(isoCode);
    setShowPhoneNumberReport(true);
  };

  useEffect(() => {}, [triggerRerender]);
  return (
    <>
      <div className="rounded-xl w-full dark:bg-[#1D1E24] bg-white dark:text-white text-gray-800 h-full overflow-y-scroll">
        {showTester && (
          <div
            className="fixed inset-0 bg-black bg-opacity-30 z-40"
            style={{ backdropFilter: "blur(4px)" }}
            onClick={() => onClose}
          ></div>
        )}

        {showTester && (
          <TesterForCustomers
            isoCode={selectedIsoCode}
            src={selectedNumber}
            setShowTester={setShowTester}
          />
        )}
        {showPhoneNumberReport && (
          <div
            className="fixed inset-0 bg-black bg-opacity-30 z-40"
            style={{ backdropFilter: "blur(4px)" }}
            onClick={() => onClose}
          ></div>
        )}

        {showPhoneNumberReport && (
          <ReportPhoneNumber
            isoCode={selectedIsoCode}
            phoneNumber={selectedNumber}
            setShowPhoneNumberReport={setShowPhoneNumberReport}
          />
        )}
        <div className="boder border-b flex justify-between h-[5vh]">
          <h3 className="font-bold text-2xl p-1 content-center flex">
            <Flag code={isoCode} />
            {numbering}
          </h3>
        </div>
        <div className="p-6 h-[15vh] flex justify-between text-sm mb-10">
          <div>
            <div className="">
              <span> Zone:</span>
              <text className="font-semibold ml-2">{zone}</text>
            </div>
            <div className="">
              <span> Prefix:</span>
              <text className="font-semibold ml-2">{prefix}</text>
            </div>
            <div className="">
              <span> Total Calls:</span>
              <text className="font-semibold ml-2">{totalCalls}</text>
            </div>
            <div className="">
              <span> Volume:</span>
              <text className="font-semibold ml-2">{volume}%</text>
            </div>
            <div className="">
              <span> Answered Calls:</span>
              <text className="font-semibold ml-2">{answeredCalls}</text>
            </div>
            <div className="">
              <span> ASR:</span>
              <text className="font-semibold ml-2">{asr}%</text>
            </div>
            <div className="flex">
              <span className="mr-2"> Campaigns:</span>
              <div className="flex flex-wrap items-end text-xs mb-[-2px]">
                {campaigns &&
                  campaigns.map((campaign, cIndex) => (
                    <span
                      key={cIndex}
                      style={{ backgroundColor: campaign.color }}
                      className="inline-flex items-end px-2 text-xs rounded-full font-medium mr-1 mb-1 text-white"
                    >
                      {campaign.name}
                    </span>
                  ))}
              </div>
            </div>
          </div>
          <div class="h-[15vh] flex flex-col justify-between">
            <div>
              <div className="flex items-end justify-end mb-2 content-start">
                {/* <button
                  onClick={() => exportToPDF()}
                  className="ml-2 border border-1 dark:border-white rounded-full p-2"
                  title="Export report to PDF"
                >
                  <PiFilePdfLight size={"30"} />
                </button> */}
              </div>
              <div className="flex items-end justify-end mb-2 content-start">
                <button
                  title="Report Phone Number"
                  className="ml-2 border border-1 dark:border-white rounded-full p-2"
                  onClick={() => {
                    phoneNumberReportClicked({
                      number: numbering,
                      isoCode: isoCode,
                    });
                  }}
                >
                  <VscWarning size={"30"} />
                </button>
              </div>
              <div className="flex items-end justify-end mb-2 content-start">
                <button
                  title="Make a test call with Voostester"
                  className="ml-2 border border-1 dark:border-white rounded-full p-2"
                  onClick={() => {
                    testerClicked({ number: numbering, isoCode: isoCode });
                  }}
                >
                  <VscCallOutgoing size={"30"} />
                </button>
              </div>
            </div>
            <div>
              <div className="flex items-center justify-end content-end text-end text-sm font-semibold">
                <text className="mr-2">Chart Intervals</text>
                <select
                  className="dark:text-white dark:bg-[#1D1E24] border border-1 border-white rounded-xl"
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  <option value="24h">24h</option>
                  <option value="12h">12h</option>
                  <option value="1h">1h</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="h-[20vh] w-full p-6">
          <CallAmountPerCli
            numbering={numbering}
            startDate={startDate}
            endDate={endDate}
            selectedOption={selectedOption}
          />
        </div>
        <div className="h-[20vh] w-full p-6">
          <TimelineAsrPerCli
            numbering={numbering}
            startDate={startDate}
            endDate={endDate}
            selectedOption={selectedOption}
          />
        </div>
        <div className="h-[20vh] w-full p-6">
          <TimelineCallCodes
            numbering={numbering}
            startDate={startDate}
            endDate={endDate}
            selectedOption={selectedOption}
          />
        </div>
      </div>
      {/* <div
        id="component-to-export"
        className="w-full  bg-white  text-gray-800 h-full mt-[10000px] overflow-y-scroll"
      >
        <div className="p-4 flex justify-between h-[5vh]">
          <h3 className="text-3xl content-center">
            Report for numbering: <text className="font-bold">{numbering}</text>
          </h3>
          <h3 className="text-sm content-center">
            Period:{" "}
            <text className="font-bold">
              {startDate} - {endDate}
            </text>
          </h3>
        </div>
        <div className="p-6 h-[15vh] flex justify-between text-sm">
          <div>
            <div className="">
              <span> Zone:</span>
              <text className="font-semibold ml-2">{zone}</text>
            </div>
            <div className="">
              <span> Prefix:</span>
              <text className="font-semibold ml-2">{prefix}</text>
            </div>
            <div className="">
              <span> Total Calls:</span>
              <text className="font-semibold ml-2">{totalCalls}</text>
            </div>
            <div className="">
              <span> Volume:</span>
              <text className="font-semibold ml-2">{volume}%</text>
            </div>
            <div className="">
              <span> Answered Calls:</span>
              <text className="font-semibold ml-2">{answeredCalls}</text>
            </div>
            <div className="">
              <span> ASR:</span>
              <text className="font-semibold ml-2">{asr}%</text>
            </div>
            <div className="flex">
              <span className=""> Campaigns:</span>
              <div className="flex flex-wrap">
                {campaigns &&
                  campaigns.map((campaign, cIndex) => (
                    <span
                      key={cIndex}
                      className="px-2 font-semibold rounded-full ml-1 mr-1 mb-1 text-white"
                    >
                      {campaign.name}
                    </span>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="h-[22vh] w-full p-6">
          <CallAmountPerCli
            numbering={numbering}
            startDate={startDate}
            endDate={endDate}
            selectedOption={selectedOption}
            isPdf={true}
          />
        </div>
        <div className="h-[22vh] w-full p-6">
          <TimelineAsrPerCli
            numbering={numbering}
            startDate={startDate}
            endDate={endDate}
            selectedOption={selectedOption}
            isPdf={true}
          />
        </div>
        <div className="h-[22vh] w-full p-6">
          <TimelineCallCodes
            numbering={numbering}
            startDate={startDate}
            endDate={endDate}
            selectedOption={selectedOption}
            isPdf={true}
          />
        </div>
      </div> */}
    </>
  );
};

export default CliDataDetails;
