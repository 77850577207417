import http from "../../http-common";

const listNumberings = () => {
  return http.get(`/api/v1/numbering/list`);
};

const getZones = () => {
  return http.get(`/api/v1/numbering/getZones`);
};
const bulkDelete = (numberingIds) => {
  return http.post(`/api/v1/numbering/bulkDelete`, numberingIds);
};

const bulkAssign = ({ campaignId, numberingIds }) => {
  return http.post(
    `/api/v1/numbering/assignNumberingsToCampaign?campaignId=${campaignId}`,
    { numberingIds }
  );
};

const bulkUnassign = ({ campaignId, numberingIds }) => {
  return http.post(
    `/api/v1/numbering/unassignNumberingsFromCampaign?campaignId=${campaignId}`,
    { numberingIds }
  );
};

const bulkMarkAsSpam = ({ campaignId, numberingIds }) => {
  return http.post(
    `/api/v1/numbering/markNumberingsAsSpam?campaignId=${campaignId}`,
    { numberingIds }
  );
};

const bulkUnMarkAsSpam = ({ campaignId, numberingIds }) => {
  return http.post(
    `/api/v1/numbering/unMarkNumberingsAsSpam?campaignId=${campaignId}`,
    { numberingIds }
  );
};

const spamTester = ({ data }) => {
  return http.post(`/api/v1/numbering/spamTester?`, data);
};

const reportPhoneNumber = ({ data }) => {
  return http.post(`/api/v1/numbering/reportPhoneNumber?`, data);
};

const PoolService = {
  listNumberings,
  getZones,
  bulkDelete,
  bulkAssign,
  bulkUnassign,
  bulkMarkAsSpam,
  bulkUnMarkAsSpam,
  spamTester,
  reportPhoneNumber,
};

export default PoolService;
