import React, { useEffect, useState } from "react";
import Pagination from "../../shared/Pagination";
import Flag from "../../shared/Flag";
import { FaFilter } from "react-icons/fa";
import PoolService from "../../../services/pool/pool";
import FiltersZonesAndCampaigns from "../../shared/FiltersZonesAndCampaigns";
import PoolDelete from "./PoolDelete";
import PoolAssignUnassign from "./PoolAssignUnassign";
import AvatarComponent from "../../shared/Avatar";
import AvatarService from "../../../services/avatar/avatar";
import PoolMarkUnMarkAsSpam from "./PoolMarkUnMarkAsSpam";
import Tester from "../../shared/Tester";
import {
  PhoneArrowUpRightIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/24/outline";
import BadgeNewFeature from "../../shared/BadgeNewFeature";
import TesterForCustomers from "../../shared/TesterForCustomers";
import ReportPhoneNumber from "../../shared/ReportPhoneNumber";
import { VscCallOutgoing, VscWarning } from "react-icons/vsc";
import PermissionsKo from "../../shared/PermissionsKo";

const PoolTable = ({ customerId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({ zones: [], campaigns: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [numberingsWithoutCampaign, setNumberingsWithoutCampaign] = useState(0);

  const [avatarUrls, setAvatarUrls] = useState({});

  useEffect(() => {
    const loadAvatars = async () => {
      const emails = Array.from(
        new Set(data.map((row) => row.createdBy || row.updatedBy))
      );
      const urls = await Promise.all(
        emails.map((email) => AvatarService.getAvatarByEmail(email))
      );
      const newAvatarUrls = emails.reduce((acc, email, index) => {
        acc[email] = urls[index];
        return acc;
      }, {});
      setAvatarUrls(newAvatarUrls);
    };

    loadAvatars();
  }, [data]);

  const filteredData = data.filter((item) => {
    const searchTermMatch = (item.numbering?.toLowerCase() || "").includes(
      searchTerm.toLowerCase()
    );
    const zoneMatch =
      filters.zones.length === 0 ||
      filters.zones.some((zone) => zone.value === item.zone);
    const campaignMatch =
      filters.campaigns.length === 0 ||
      item.campaigns.some((campaign) =>
        filters.campaigns.map((filter) => filter.value).includes(campaign.name)
      );
    return searchTermMatch && zoneMatch && campaignMatch;
  });

  const totalItems = filteredData.length;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showPoolDelete, setShowPoolDelete] = useState(false);
  const [showAssignUnassign, setShowAssignUnassign] = useState(false);

  const [showMarkUnMarkAsSpam, setShowMarkUnMarkAsSpam] = useState(false);

  const [isUnauthorized, setIsUnauthorized] = useState(false);

  const [triggerRerender, setTriggerRerender] = useState(false);

  const [selectedNumber, setSelectedNumber] = useState();
  const [selectedIsoCode, setSelectedIsoCode] = useState();

  const [showTester, setShowTester] = useState(false);
  const [showPhoneNumberReport, setShowPhoneNumberReport] = useState(false);

  const onClose = () => {
    if (
      showPoolDelete ||
      showAssignUnassign ||
      showMarkUnMarkAsSpam ||
      showTester ||
      showPhoneNumberReport
    ) {
      setShowPoolDelete(false);
      setShowAssignUnassign(false);
      setShowMarkUnMarkAsSpam(false);
      setShowTester(false);
      setShowPhoneNumberReport(false);
      setTriggerRerender((prev) => !prev);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleRowSelection = (id) => {
    setSelectedRows((currentSelectedRows) =>
      currentSelectedRows.includes(id)
        ? currentSelectedRows.filter((rowId) => rowId !== id)
        : [...currentSelectedRows, id]
    );
  };

  const toggleSelectAll = () => {
    if (selectedRows.length === currentItems.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(currentItems.map((item) => item.id));
    }
  };

  useEffect(() => {
    setIsLoading(true);
    PoolService.listNumberings({ customerId })
      .then(({ data: { data: numberings } }) => {
        const count = numberings.reduce(
          (acc, cur) => acc + (cur.campaigns.length === 0 ? 1 : 0),
          0
        );
        setNumberingsWithoutCampaign(count);
        setData(numberings);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response && error.response.status === 403) {
          setIsUnauthorized(true);
        }
      });
  }, [triggerRerender, customerId]);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const clearFilters = () => {
    setFilters({
      zones: [],
      campaigns: [],
    });
  };

  const testerClicked = ({ number, isoCode }) => {
    setSelectedNumber(number);
    setSelectedIsoCode(isoCode);
    setShowTester(true);
  };

  const phoneNumberReportClicked = ({ number, isoCode }) => {
    setSelectedNumber(number);
    setSelectedIsoCode(isoCode);
    setShowPhoneNumberReport(true);
  };

  const numberOfFiltersApplied = Object.values(filters).reduce(
    (totalLength, value) => {
      if (Array.isArray(value)) {
        return totalLength + value.length;
      }

      return totalLength + (value !== null ? 1 : 0);
    },
    0
  );
  return (
    <div class="flex flex-col h-full">
      {isUnauthorized ? (
        <PermissionsKo />
      ) : (
        <>
          <div className="p-4">
            <div className="flex justify-between items-start">
              <div className="flex items-center">
                <div className="relative inline-block text-left">
                  <button
                    onClick={toggleDropdown}
                    disabled={selectedRows.length === 0}
                    className="hidden sm:block dark:bg-gray-900 dark:text-white rounded-lg font-semibold px-2"
                  >
                    Actions ({selectedRows.length})
                  </button>
                  {showPoolDelete && (
                    <div
                      className="fixed inset-0 bg-black bg-opacity-30 z-40"
                      style={{ backdropFilter: "blur(4px)" }}
                      onClick={() => setShowPoolDelete(false)}
                    ></div>
                  )}

                  {showPoolDelete && (
                    <PoolDelete
                      customerId={customerId}
                      poolSelected={selectedRows}
                      setShowPoolDelete={setShowPoolDelete}
                    />
                  )}

                  {showAssignUnassign && (
                    <div
                      className="fixed inset-0 bg-black bg-opacity-30 z-40"
                      style={{ backdropFilter: "blur(4px)" }}
                      onClick={() => onClose}
                    ></div>
                  )}

                  {showAssignUnassign && (
                    <PoolAssignUnassign
                      customerId={customerId}
                      poolSelected={selectedRows}
                      setShowAssignUnassign={onClose}
                    />
                  )}

                  {showTester && (
                    <div
                      className="fixed inset-0 bg-black bg-opacity-30 z-40"
                      style={{ backdropFilter: "blur(4px)" }}
                      onClick={() => onClose}
                    ></div>
                  )}

                  {showTester && (
                    <TesterForCustomers
                      isoCode={selectedIsoCode}
                      src={selectedNumber}
                      setShowTester={setShowTester}
                    />
                  )}
                  {showPhoneNumberReport && (
                    <div
                      className="fixed inset-0 bg-black bg-opacity-30 z-40"
                      style={{ backdropFilter: "blur(4px)" }}
                      onClick={() => onClose}
                    ></div>
                  )}

                  {showPhoneNumberReport && (
                    <ReportPhoneNumber
                      isoCode={selectedIsoCode}
                      phoneNumber={selectedNumber}
                      setShowPhoneNumberReport={setShowPhoneNumberReport}
                    />
                  )}

                  {showMarkUnMarkAsSpam && (
                    <div
                      className="fixed inset-0 bg-black bg-opacity-30 z-40"
                      style={{ backdropFilter: "blur(4px)" }}
                      onClick={() => onClose}
                    ></div>
                  )}

                  {showMarkUnMarkAsSpam && (
                    <PoolMarkUnMarkAsSpam
                      customerId={customerId}
                      poolSelected={selectedRows}
                      setShowMarkUnMarkAsSpam={onClose}
                    />
                  )}

                  {showDropdown && (
                    <div
                      style={{ backdropFilter: "blur(4px)" }}
                      className="bg-opacity-30 z-40 origin-top-right absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-gray-900 ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <div
                        className="py-1"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        <button
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
                          onClick={() => {
                            setShowDropdown(false);
                            setShowAssignUnassign(true);
                          }}
                        >
                          Assign/Unassign
                        </button>
                        {localStorage.getItem("mt") && (
                          <>
                            <button
                              className="block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
                              onClick={() => {
                                setShowDropdown(false);
                                setShowPoolDelete(true);
                              }}
                            >
                              Delete
                            </button>
                            <button
                              className="block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
                              onClick={() => {
                                setShowDropdown(false);
                                setShowMarkUnMarkAsSpam(true);
                              }}
                            >
                              Mark/Unmark as SPAM
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {/* <div className="w-px h-6 bg-gray-300 mx-2"></div>
            <PlusCircleIcon className="h-6 w-6 dark:text-white" /> */}
                <div className="hidden sm:block w-px h-6 bg-gray-300 mx-2"></div>
                {numberOfFiltersApplied > 0 && (
                  <div className="mr-2 py-1 bg-black dark:bg-gray-900 text-white text-xs text-center items-center justify-center rounded-full px-2">
                    {numberOfFiltersApplied}
                  </div>
                )}
                <div className="relative flex">
                  <button onClick={toggleFilters} className="z-50">
                    <FaFilter className="dark:text-white" />
                  </button>
                  {showFilters && (
                    <div
                      className="fixed inset-0 bg-black bg-opacity-30 z-40"
                      style={{ backdropFilter: "blur(4px)" }}
                      onClick={() => setShowFilters(false)}
                    ></div>
                  )}
                  {showFilters && (
                    <FiltersZonesAndCampaigns
                      showFilters={showFilters}
                      filters={filters}
                      setFilters={setFilters}
                      setShowFilters={setShowFilters}
                      selectedZones={filters.zones}
                      selectedCampaigns={filters.campaigns}
                    />
                  )}
                  {numberOfFiltersApplied > 0 && (
                    <div className="flex">
                      <div className="w-px h-6 bg-gray-300 mx-2"></div>
                      <button
                        className="mr-4 text-xs text-white bg-red-900 rounded-lg px-2"
                        onClick={clearFilters}
                      >
                        Clear filters
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center">
                {numberingsWithoutCampaign > 0 && (
                  <div className="hidden lg:block lg:text-center lg:p-2 lg:w-full">
                    <span className="text-xs dark:text-white bg-yellow-500 rounded-lg px-2 py-1">
                      Warning: there are <b>{numberingsWithoutCampaign}</b>{" "}
                      numbering(s) with no associated campaign
                    </span>
                  </div>
                )}
                <input
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="Search by numbering..."
                  className="input input-bordered border border-black bg-[#F7F8FC] dark:bg-gray-900 rounded-lg text-sm dark:text-white px-2 py-0.5"
                />
              </div>
            </div>
            {numberingsWithoutCampaign > 0 && (
              <div className="text-center p-2 w-full lg:hidden">
                <span className="text-xs dark:text-white bg-yellow-500 rounded-lg px-2 py-1">
                  Warning: there are <b>{numberingsWithoutCampaign}</b>{" "}
                  numbering(s) with no associated campaign
                </span>
              </div>
            )}
          </div>

          <div class="overflow-x-auto hidden sm:block">
            <table class="min-w-full table-auto dark:text-white ">
              <thead class="border-b sticky bg-slate-200 dark:bg-gray-700">
                <tr>
                  <th class="text-left px-4 py-2">
                    {" "}
                    <input
                      type="checkbox"
                      onChange={toggleSelectAll}
                      checked={
                        selectedRows.length === currentItems.length &&
                        currentItems.length !== 0
                      }
                      indeterminate={
                        selectedRows.length > 0 &&
                        selectedRows.length < currentItems.length
                      }
                    />
                  </th>
                  <th class="text-left px-4 py-2">Numbering</th>
                  {/* <th class="text-left px-4 py-2">Spam</th> */}
                  <th class="text-left px-4 py-2">Country</th>
                  <th class="text-left px-4 py-2">Prefix</th>
                  <th class="text-left px-4 py-2">Zone</th>
                  <th class="text-left px-4 py-2">Campaigns</th>
                  <th class="text-left px-4 py-2">Created By</th>
                  <th class="text-left px-4 py-2">Created At</th>
                  <th class="text-left px-4 py-2">Updated At</th>
                  <th class="text-left px-4 py-2">Features</th>
                </tr>
              </thead>
              {isLoading ? (
                <tr>
                  <td colSpan="10" className="">
                    <div className="relative">
                      <div className="w-full h-0.5">
                        <div className="h-full bg-blue-500 animate-progress-bar"></div>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  <tbody class="bg-white dark:bg-gray-800">
                    {currentItems.length === 0 ? (
                      <tr>
                        <td colSpan="12" className="text-center py-2">
                          <div className="flex justify-center items-center w-full">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-mood-empty-filled mr-1"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="2"
                              stroke="currentColor"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <path
                                d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-2 10.66h-6l-.117 .007a1 1 0 0 0 0 1.986l.117 .007h6l.117 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-5.99 -5l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm6 0l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z"
                                stroke-width="0"
                                fill="currentColor"
                              ></path>
                            </svg>
                            - No numberings found
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {currentItems.map((item, index) => (
                          <tr
                            key={index}
                            class="border-b dark:border-gray-700 text-xs font-medium"
                          >
                            <td className="px-4 py-2">
                              <input
                                type="checkbox"
                                checked={selectedRows.includes(item.id)}
                                onChange={() => toggleRowSelection(item.id)}
                              />
                            </td>
                            <td class="px-4 py-2">{item.numbering} </td>
                            {/* <td class="px-4 py-2">
                              {item.spam && (
                                <span
                                  style={{ backgroundColor: "black" }}
                                  className="inline-flex items-center px-2 text-xs rounded-full font-medium mr-1 mb-1 text-white"
                                >
                                  SPAM
                                </span>
                              )}
                            </td> */}
                            <td class="px-4 py-2 flex">
                              <Flag code={item.isoCode} class="mr-1" />{" "}
                              {item.isoCode}
                            </td>
                            <td class="px-4 py-2">{item.prefix}</td>
                            <td class="px-4 py-2">{item.zone}</td>
                            <td className="px-4 py-2">
                              <div className="flex flex-wrap items-center">
                                {item.campaigns &&
                                  item.campaigns.map((campaign, cIndex) => (
                                    <span
                                      key={cIndex}
                                      style={{
                                        backgroundColor: campaign.color,
                                      }}
                                      className="inline-flex items-center px-2 text-xs rounded-full font-medium mr-1 mb-1 text-white"
                                    >
                                      {campaign.name}
                                    </span>
                                  ))}
                              </div>
                            </td>
                            <td className="px-4 pt-2 ">
                              <span
                                className={`inline-flex items-center rounded-full pr-1 text-xs font-semibold `}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {item.createdBy && (
                                    <div className="mr-2">
                                      <AvatarComponent
                                        email={item.createdBy}
                                        height={"6"}
                                        width={"6"}
                                        avatarUrl={avatarUrls[item.createdBy]}
                                      />
                                    </div>
                                  )}
                                  {item.createdBy}
                                </div>
                              </span>
                            </td>
                            <td class="px-4 py-2">
                              {new Date(item.createdAt).toLocaleString(
                                undefined,
                                {
                                  timeZone:
                                    Intl.DateTimeFormat().resolvedOptions()
                                      .timeZone,
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                }
                              )}
                            </td>
                            <td class="px-4 py-2">
                              {new Date(item.updatedAt).toLocaleString(
                                undefined,
                                {
                                  timeZone:
                                    Intl.DateTimeFormat().resolvedOptions()
                                      .timeZone,
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                }
                              )}
                            </td>
                            <td className="px-4 py-2 items-center justify-left">
                              <button
                                title="Report Phone Number"
                                className="ml-2"
                                onClick={() => {
                                  phoneNumberReportClicked({
                                    number: item.numbering,
                                    isoCode: item.isoCode,
                                  });
                                }}
                              >
                                <VscWarning size={"15"} />
                              </button>
                              <button
                                title="Make a test call with Voostester"
                                className="ml-2"
                                onClick={() => {
                                  testerClicked({
                                    number: item.numbering,
                                    isoCode: item.isoCode,
                                  });
                                }}
                              >
                                <VscCallOutgoing size={"15"} />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </>
              )}
            </table>
          </div>

          {/* <div class="sm:hidden overflow-y-scroll">
        {isLoading ? (
          <tbody>
            <tr>
              <td colSpan="100%">
                <div className="loading-bar"></div>
              </td>
            </tr>
          </tbody>
        ) : (
          <>
            {currentItems.length === 0 ? (
              <tr className="">
                <td colSpan="9" className="text-center py-2">
                  <div className="flex justify-center  items-center w-full dark:text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-mood-empty-filled mr-1"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-2 10.66h-6l-.117 .007a1 1 0 0 0 0 1.986l.117 .007h6l.117 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-5.99 -5l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm6 0l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z"
                        stroke-width="0"
                        fill="currentColor"
                      ></path>
                    </svg>
                    - No numberings found
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {currentItems.map((item, index) => (
                  <div
                    key={index}
                    class="bg-white dark:bg-gray-900 p-4 mb-2 rounded-lgg shadow dark:text-white"
                  >
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">Numbering:</span>
                      <span>{item.numbering}</span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">Country:</span>
                      <div class="flex items-center">
                        <Flag code={item.isoCode} class="mr-1" /> {item.isoCode}
                      </div>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">Prefix:</span>
                      <span>{item.prefix}</span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">Zone:</span>
                      <span>{item.zone}</span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">Campaigns:</span>
                      <span>
                        {item.campaigns &&
                          item.campaigns.map((campaign, cIndex) => (
                            <span
                              key={cIndex}
                              style={{ backgroundColor: campaign.color }}
                              className="inline-flex items-center px-2  text-xs rounded-full font-medium mr-1 mb-1 text-white"
                            >
                              {campaign.name}
                            </span>
                          ))}
                      </span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">Created By:</span>
                      <span>{item.createdBy}</span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="font-bold">Created At:</span>
                      <span>
                        {new Date(item.createdAt).toLocaleString(undefined, {
                          timeZone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })}
                      </span>
                    </div>
                    <div class="flex justify-between">
                      <span class="font-bold">Updated At:</span>
                      <span>
                        {new Date(item.updatedAt).toLocaleString(undefined, {
                          timeZone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })}
                      </span>
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </div> */}

          <Pagination
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setItemsPerPage={setItemsPerPage}
          />
        </>
      )}
    </div>
  );
};

export default PoolTable;
