import LogoWhite from "../../assets/images/LogoWhite.svg";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import LoginService from "../../services/login/login";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuth();
  let navigate = useNavigate();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email || !password) {
      setError("All fields are required");
      return;
    }

    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email");
      return;
    }

    try {
      setIsLoading(true);
      const {
        data: {
          accessToken,
          avatar,
          name,
          isMaster,
          companyData: { companyName: customerName, logoURL: companyLogo },
        },
      } = await LoginService.login(email, password);

      localStorage.setItem("avatar", avatar);
      localStorage.setItem("name", name);
      localStorage.setItem("email", email);
      localStorage.setItem("customerName", customerName);
      localStorage.setItem("customerLogo", companyLogo);

      if (isMaster) {
        localStorage.setItem("mt", accessToken);
        navigate("/master/customers");
      } else {
        localStorage.setItem("bt", accessToken);
        navigate("/dashboard");
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Login error", error);
      setError("Login error. Please check your credentials.");
    }
  };

  return (
    <div className="bg-black">
      <section className="z-auto max-w-2xl mx-auto bg-clip-content justify-center content-center flex flex-col">
        <div className="absolute z-20 inset-0 w-full bg-black bg-[linear-gradient(to_right,#ffffff30_1px,transparent_1px),linear-gradient(to_bottom,#ffffff30_1px,transparent_1px)] bg-[size:44px_44px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_60%,transparent_100%)]"></div>
        <div className="z-40 h-screen flex flex-col gap-8 justify-center items-center">
          <img src={LogoWhite} width={300} height={200} alt="logo-login"></img>
          <div className="bg-white p-8 rounded-xl flex flex-col gap-2 w-[80%] lg:w-[450px] max-w-2xl mx-auto">
            <h1 className="text-center text-4xl font-bold">Log In</h1>
            <p className="text-xl text-black text-center font-extralight">
              Welcome to ASR Vooster
            </p>
            <hr className="mt-8 bg-black [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_60%,transparent_100%)]"></hr>
            <div className="flex flex-col gap-4 py-8">
              <div className="flex flex-col gap-1">
                <label className="font-extralight text-md text-gray-600">
                  Username
                </label>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="bg-gray-100 h-12 p-4 rounded-md"
                  type="text"
                ></input>
              </div>
              <div className="flex flex-col gap-1">
                <label className="font-extralight text-md text-gray-600">
                  Password
                </label>
                <div className="relative">
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={showPassword ? "text" : "password"}
                    className="bg-gray-100 h-12 p-4 rounded-md w-full"
                    required
                  ></input>
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer text-gray-600"
                    onClick={toggleShowPassword}
                  >
                    {showPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    )}
                  </div>
                </div>

                {/* <p className="text-xs font-extralight text-slate-400">
                  Forgot Password?
                </p> */}
              </div>
            </div>

            <p className="text-sm text-red-600">{error || <>&nbsp;</>}</p>
            <button
              onClick={handleSubmit}
              className="relative inline-flex h-12 overflow-hidden rounded-md p-[1.5px] focus:outline-none focus:ring-4 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
            >
              <span className="absolute inset-[-1000%] animate-[spin_4s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#8331A7_0%,#3FCEA6_50%,#8331A7_100%)]" />
              <span
                className={`inline-flex h-full w-full cursor-pointer items-center justify-center rounded-md ${
                  isLoading ? "bg-gray-400" : "bg-black"
                }  transition-all ease-in-out duration-300 hover:bg-[#222222] px-8 py-1 text-sm font-medium text-white backdrop-blur-3xl`}
              >
                Log In
              </span>
            </button>
          </div>
          <span className="inline-flex animate-background-shine bg-[linear-gradient(110deg,#939393,45%,#f9f9f9,55%,#939393)] bg-[length:200%_100%] bg-clip-text text-md text-transparent">
            Powered by Astroline
          </span>
          <div className=" absolute bottom-4 flex gap-6 pt-4 justify-center">
            <p className="text-xs font-extralight text-slate-400">
              Aviso legal
            </p>
            <p className="text-xs font-extralight text-slate-400">
              Política de Privacidad
            </p>
            <p className="text-xs font-extralight text-slate-400">
              Web Astroline
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
