import http from "../../http-common";

const listUsers = () => {
  return http.get(`/api/v1/users`);
};

const createUser = ({ data }) => {
  return http.post(`/api/v1/users/create`, data);
};

const updateUser = ({ userId, data }) => {
  return http.put(`/api/v1/users/update?userId=${userId}`, data);
};

const deleteUser = ({ userId }) => {
  return http.delete(`/api/v1/users/delete?userId=${userId}`);
};

const UserService = {
  listUsers,
  createUser,
  updateUser,
  deleteUser,
};

export default UserService;
