import http from "../../http-common";

const listCampaigns = () => {
  return http.get(`/api/v1/campaigns/list`);
};

const addCampaign = ({ customerId, data }) => {
  return http.post(`/api/v1/campaigns/create?customerId=${customerId}`, data);
};

const updateCampaign = ({ customerId, id, data }) => {
  return http.put(
    `/api/v1/campaigns/update?campaignId=${id}&customerId=${customerId}`,
    data
  );
};
const deleteCampaign = (id) => {
  return http.delete(`/api/v1/campaigns/delete?campaignId=${id}`);
};

const CampaignService = {
  listCampaigns,
  addCampaign,
  deleteCampaign,
  updateCampaign,
};

export default CampaignService;
